import React from 'react';
import { StyleSheet, Text, View, Platform, Image, Dimensions } from 'react-native';
import * as SplashScreen from 'expo-splash-screen';
import * as Font from 'expo-font';
import { NavigationContainer } from '@react-navigation/native';
import Stack from './navigation/Stack';
import AsyncStorage from '@react-native-async-storage/async-storage';
import * as Crypto from 'expo-crypto';
import * as Notifications from 'expo-notifications';

let customFonts = {
  'DM-Bold': require('./assets/fonts/DMSans-Bold.ttf'),
  'DM-Medium': require('./assets/fonts/DMSans-Medium.ttf'),
  'DM-Regular': require('./assets/fonts/DMSans-Regular.ttf')
};

const config = {
  screens: {
    Loading: '',
    Login: 'login',
    Register: 'signup',
    ResetPassword: 'recupera-password',
    PazienteNuovo: 'paziente/new',
    VisitaNuovo: 'visita/new',
    Profilo: 'profilo',
    EditProfilo: 'profilo/edit',
    PazienteModifica: 'paziente/edit',
    Statistiche: 'statistiche',
    VisitaModifica: 'visita/edit',
    SoobPazienti: 'pazienti/soob',
    Message: 'messaggi',
    VisiteUtente: 'visite/utente',
    UserActive: 'utente/active',
    Automazioni: 'automazioni',
    AutomazioniList: 'automazioni/list',
    AutomazioniCategoryList: 'automazioni/categoria',
    EditInformazioni: 'informazioni/edit',
    Visite: 'visite',
    LeadModifica: 'lead/edit',
    PropostaNuovo: 'proposte/new',
    PropostaModifica: 'proposte/edit',
    Campagne: 'campagne',
    Impostazioni: 'impostazioni',
    Dashboard: 'home',
    Statistic: 'dashboard',
    Paziente: 'pazienti',
    App: 'app',
    Agenda: 'agenda',
    Studi: 'sale',
    Operatori: 'operatori',
    OrariOperatore: 'operatori/orari',
    Richiami: 'richiami',
    Lead: 'lead',
    Proposte: 'proposte',
    AgendaBook: 'agenda/book',
    Appuntamenti: 'appuntamenti',
    SelectAutomazione: 'seleziona/automazione',
    Tutorial: 'tutorial'
  },
};

const linking = {
  prefixes: ['https://clinicautomation.it/', 'clinicautomation://'],
  //prefixes: ['http://localhost:8000//', 'localhost:8000//'],
  config,
};

export default class App extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      fontsLoaded: false,
    }
  }

  _loadFontsAsync = async () => {
    try {
      // Keep the splash screen visible while we fetch resources
      await SplashScreen.preventAutoHideAsync();
      // Pre-load fonts, make any API calls you need to do here
      await Font.loadAsync(customFonts);
    } catch (e) {
      console.warn(e);
    } finally {
      // Tell the application to render
      this.setState({ fontsLoaded: true });
      await SplashScreen.hideAsync();
    }
  }

  async componentDidMount() {
    this._loadFontsAsync()
    //this.getFacebookData()
    if (Platform.OS === 'android') {
      await Notifications.setNotificationChannelAsync('visitreporter_push', {
        name: 'VisitReporter',
        importance: Notifications.AndroidImportance.MAX,
        vibrationPattern: [0, 250, 250, 250],
        lightColor: '#FF231F7C',
        sound: true
      });
    }
  }

  /*getFacebookData = async () => {
    if (Platform.OS == 'ios') {
      const { status: existingStatus } = await Facebook.getPermissionsAsync()
      let finalStatus = existingStatus;
      if (existingStatus != 'granted') {
        const { status } = await Facebook.requestPermissionsAsync()
        finalStatus = status;
      }
      if (finalStatus != 'granted') {
        return;
      } else {
        await Facebook.initializeAsync({
          appId: '788786742147187',
          appName: 'Visit Reporter'
        });
        const promise = await Facebook.setAutoLogAppEventsEnabledAsync(true)
        const promiseAdv = await Facebook.setAdvertiserTrackingEnabledAsync(true)
        await Facebook.logEventAsync('ViewContent')
        await Facebook.flushAsync()
      }
    } else {
      await Facebook.initializeAsync({
        appId: '788786742147187',
        appName: 'Visit Reporter'
      });
      const promise = await Facebook.setAutoLogAppEventsEnabledAsync(true)
      await Facebook.logEventAsync('ViewContent')
      await Facebook.flushAsync()
    }
  }*/

  render() {
    if (this.state.fontsLoaded) {
      return(
        <NavigationContainer linking={linking}>
          <Stack />
        </NavigationContainer>
      );
    } else {
      return <Image source={require('./assets/splash.png')} resizeMode='cover' style={{ width: Dimensions.get('screen').width, height: Dimensions.get('screen').height }} />;
    }
  }
}
