import React from 'react';
import { StyleSheet, Text, View, StatusBar, Dimensions, Image, ScrollView, Alert, TouchableOpacity, TextInput } from 'react-native';
import { ActivityIndicator, Switch } from 'react-native-paper';
import Constants from 'expo-constants';
import { AntDesign, Feather } from '@expo/vector-icons';
import AsyncStorage from '@react-native-async-storage/async-storage';
import * as Crypto from 'expo-crypto';
import Moment from 'moment';
import 'moment/locale/it';
import * as Device from 'expo-device';
import CryptoJS from 'crypto-js';
import axios from 'axios';

export default class HomeScreen extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            dataScadenza: null,
            isProva: null,
            image: '',
            preventiviInAttesa: 0,
            isAutomazioniActive: false,
            emailTest: '',
            idUtente: 0,
            isActiveStatistic: false
        }
    }

    async componentDidMount() {
        this._unsubscribe = this.props.navigation.addListener('focus', () => {
            this.checkStatistic()
            this.getImage()
            this.getBilling()
            this.getPreventiviAttesa()
            this.checkAutomazioni()
            this.getEmailTest()
        });
        this.checkStatistic()
        this.getImage()
        this.getBilling()
        this.getPreventiviAttesa()
        this.checkAutomazioni()
        this.getEmailTest()
    }

    componentWillUnmount() {
        this._unsubscribe
    }

    checkStatistic = async () => {
        const stattistiche = await AsyncStorage.getItem('statistiche')
        if (stattistiche == 'true') {
            this.setState({ isActiveStatistic: true })
        } else {
            this.setState({ isActiveStatistic: false })
        }
    }

    getEmailTest = async () => {
        const email = await AsyncStorage.getItem('email')
        const id = await AsyncStorage.getItem('id')
        this.setState({ emailTest: email.split('@')[1], idUtente: id })
    }

    checkAutomazioni = async () => {
        const id = await AsyncStorage.getItem('id')
        const token = CryptoJS.MD5('8Hsh-zjh83sdhy83Hbz').toString(CryptoJS.enc.Hex)
        let formData = new FormData();
        formData.append('token', token);
        formData.append('id', id);
        axios.post('https://clinicautomation.it/app/check_automazioni.php', formData, {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'multipart/form-data'
            }
        })
        .then(async (response) => {
            const json = response.data;
            if (json.code == true) {
                this.setState({ isAutomazioniActive: json.active })
            } else {
                Alert.alert(
                    "Attenzione",
                    json.message,
                    [
                        { text: "OK" }
                    ]
                );
            }
        }).catch((error) => {
            console.error(error);
        })
    }

    getBilling = async () => {
        const id = await AsyncStorage.getItem('id')
        const token =  CryptoJS.MD5('8Hsh-zjh83sdhy83Hbz').toString(CryptoJS.enc.Hex)
        let formData = new FormData();
        formData.append('token', token);
        formData.append('id', id);
        axios.post('https://clinicautomation.it/app/get_subscription_detail.php', formData, {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'multipart/form-data'
            }
        })
        .then(async (response) => {
            const json = response.data;
            if (json.code == true) {
                this.setState({ dataScadenza: json.data_scadenza, isProva: json.is_prova })
            } else {
                Alert.alert(
                    "Attenzione",
                    json.message,
                    [
                        { text: "OK" }
                    ]
                );
            }
        }).catch((error) => {
            console.error(error);
        })
    }

    getPreventiviAttesa = async () => {
        const id = await AsyncStorage.getItem('id')
        const token =  CryptoJS.MD5('8Hsh-zjh83sdhy83Hbz').toString(CryptoJS.enc.Hex)
        let formData = new FormData();
        formData.append('token', token);
        formData.append('id_user', id);
        axios.post('https://clinicautomation.it/app/preventivi_in_attesa.php', formData, {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'multipart/form-data'
            }
        })
        .then(async (response) => {
            const json = response.data;
            this.setState({ preventiviInAttesa: json.length })
        }).catch((error) => {
            console.error(error);
        })
    }

    getImage = async () => {
        let image = await AsyncStorage.getItem('image')
        if (image != null && image != undefined && image != '') {
            this.setState({ image })
        }
    }

    render() {
        return(
            <View style={styles.container}>
                <StatusBar translucent={true} barStyle='light-content' backgroundColor='transparent' />
                <View style={styles.headerHome}>
                    <View style={styles.header}>
                        <TouchableOpacity style={styles.backButton} onPress={() => this.props.navigation.navigate('Profilo')}>
                            <Image source={(this.state.image != '') ? { uri: this.state.image } : require('../assets/user-default-dentop.png')} resizeMode='cover' style={{ width: 37, height: 37, borderRadius: 8 }} />
                            <Text style={{ fontFamily: 'DM-Medium', fontSize: 14, color: '#fff', marginLeft: 7 }}>Profilo</Text>
                        </TouchableOpacity>
                        <TouchableOpacity style={styles.backButton} onPress={() => this.props.navigation.navigate('PazienteNuovo')}>
                            <AntDesign name='pluscircleo' color='#fff' size={23} />
                            <Text style={{ fontFamily: 'DM-Medium', fontSize: 14, color: '#fff', marginLeft: 7 }}>Paziente</Text>
                        </TouchableOpacity>
                    </View>
                    <View style={{
                        alignItems: 'center',
                        marginBottom: 20,
                        marginTop: (Dimensions.get('screen').width <= 500) ? 10 : 4.5,
                        position: (Dimensions.get('screen').width <= 500) ? 'relative' : 'absolute',
                        top: 0,
                        left: 0,
                        right: 0,
                        width: '100%',
                        zIndex: (Dimensions.get('screen').width <= 500) ? 0 : -1
                    }}>
                        <Image source={require('../assets/logo-clinic.png')} resizeMode='contain' style={{ width: (Dimensions.get('screen').width <= 500) ? Dimensions.get('screen').width - 80 : Dimensions.get('screen').width - 120, height: (Dimensions.get('screen').width <= 500) ? 80 : 60 }} />
                    </View>
                </View>
                <ScrollView showsVerticalScrollIndicator={false}>
                    <View style={styles.containerLink}>
                        <View style={{ marginVertical: 20 }}>
                            <TouchableOpacity onPress={() => this.props.navigation.navigate('AutomazioniList', { item: JSON.stringify({ id: "11", nome_categoria: "Promemoria Appuntamenti" }) })} style={[styles.buttonLink, styles.responsiveCall, { paddingHorizontal: 20, paddingVertical: 20, justifyContent: 'space-between' }]}>
                                <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                                    <Image source={require('../assets/dental-automation.png')} resizeMode='contain' style={{ width: 50, height: 50 }} />
                                    <View style={{ marginLeft: 15 }}>
                                        <Text style={{ fontFamily: 'DM-Medium', fontSize: 18 }}>Promemoria</Text>
                                        <Text style={{ fontFamily: 'DM-Regular', fontSize: 14, color: '#6d6e72' }}>Aggiungi appuntamento</Text>
                                    </View>
                                </View>
                                <AntDesign name='pluscircleo' color='#0070FF' size={23} />
                            </TouchableOpacity>
                        </View>
                        <Text style={{ fontFamily: 'DM-Bold', fontSize: 20, color: '#FFF' }}>Cosa stai cercando?</Text>
                        <View style={{ marginTop: 20, marginBottom: (Dimensions.get('screen').width <= 500) ? 20 : (this.state.preventiviInAttesa > 0 && this.state.isActiveStatistic == true) ? 20 : 80, alignItems: 'center', flexDirection: (Dimensions.get('screen').width <= 500) ? 'column' : 'row', justifyContent: (Dimensions.get('screen').width <= 500) ? 'flex-start' : 'space-between', flexWrap: (Dimensions.get('screen').width <= 500) ? 'nowrap' : 'wrap' }}>
                            <TouchableOpacity style={[styles.buttonLink, styles.responsiveCallButton, { paddingHorizontal: 20, paddingVertical: 20, justifyContent: 'space-between', marginBottom: 15 }]} onPress={() => this.props.navigation.navigate('Appuntamenti')}>
                                <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                                    <Image source={require('../assets/calendar.png')} resizeMode='contain' style={{ width: 50, height: 50 }} />
                                    <View style={{ marginLeft: 15 }}>
                                        <Text style={{ fontFamily: 'DM-Medium', fontSize: 16 }}>Agenda elettronica</Text>
                                        <Text style={{ fontFamily: 'DM-Regular', fontSize: 12, color: '#6d6e72' }}>Gestisci la tua agenda</Text>
                                    </View>
                                </View>
                                <Feather name='arrow-right-circle' color='#0070FF' size={25} />
                            </TouchableOpacity>
                            <TouchableOpacity style={[styles.buttonLink, styles.responsiveCallButton, { paddingHorizontal: 20, paddingVertical: 20, justifyContent: 'space-between', marginBottom: 15 }]} onPress={() => this.props.navigation.navigate('Richiami')}>
                                <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                                    <Image source={require('../assets/richiami.png')} resizeMode='contain' style={{ width: 50, height: 50 }} />
                                    <View style={{ marginLeft: 15 }}>
                                        <Text style={{ fontFamily: 'DM-Medium', fontSize: 16 }}>Programma remind</Text>
                                        <Text style={{ fontFamily: 'DM-Regular', fontSize: 12, color: '#6d6e72' }}>Gestione scadenziario richiami</Text>
                                    </View>
                                </View>
                                <Feather name='arrow-right-circle' color='#0070FF' size={25} />
                            </TouchableOpacity>
                            <TouchableOpacity style={[styles.buttonLink, styles.responsiveCallButton, { paddingHorizontal: 20, paddingVertical: 20, justifyContent: 'space-between', marginBottom: 15 }]} onPress={() => this.props.navigation.navigate('AutomazioniList', { item: JSON.stringify({ id: "13", nome_categoria: "Scenari" }) })}>
                                <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                                    <Image source={require('../assets/icon-automazioni.png')} resizeMode='contain' style={{ width: 50, height: 50 }} />
                                    <View style={{ marginLeft: 15 }}>
                                        <Text style={{ fontFamily: 'DM-Medium', fontSize: 16 }}>Scenari</Text>
                                        <Text style={{ fontFamily: 'DM-Regular', fontSize: 12, color: '#6d6e72' }}>Automazione</Text>
                                    </View>
                                </View>
                                <Feather name='arrow-right-circle' color='#0070FF' size={25} />
                            </TouchableOpacity>
                            {/* <TouchableOpacity style={[styles.buttonLink, styles.responsiveCallButton, { paddingHorizontal: 20, paddingVertical: 20, justifyContent: 'space-between', marginBottom: 15 }]} onPress={() => this.props.navigation.navigate('AutomazioniList', { item: JSON.stringify({ id: "5", nome_categoria: "Storico app. (Non fissare nuovi appuntamenti da queste sequenze)" }) })}>
                                <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                                    <Image source={require('../assets/storico.png')} resizeMode='contain' style={{ width: 50, height: 50 }} />
                                    <View style={{ marginLeft: 15 }}>
                                        <Text style={{ fontFamily: 'DM-Medium', fontSize: 16 }}>Storico</Text>
                                        <Text style={{ fontFamily: 'DM-Regular', fontSize: 12, color: '#6d6e72' }}>Automazione</Text>
                                    </View>
                                </View>
                                <Feather name='arrow-right-circle' color='#0070FF' size={25} />
                            </TouchableOpacity> */}
                            <TouchableOpacity style={[styles.buttonLink, styles.responsiveCallButton, { paddingHorizontal: 20, paddingVertical: 20, justifyContent: 'space-between', marginBottom: 15 }]} onPress={() => this.props.navigation.navigate('Campagne')}>
                                <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                                    <Image source={require('../assets/icon-campagne.png')} resizeMode='contain' style={{ width: 50, height: 50 }} />
                                    <View style={{ marginLeft: 15 }}>
                                        <Text style={{ fontFamily: 'DM-Medium', fontSize: 16 }}>Campagne massive</Text>
                                        <Text style={{ fontFamily: 'DM-Regular', fontSize: 12, color: '#6d6e72' }}>Automazioni offerta</Text>
                                    </View>
                                </View>
                                <Feather name='arrow-right-circle' color='#0070FF' size={25} />
                            </TouchableOpacity>
                            <TouchableOpacity style={[styles.buttonLink, styles.responsiveCallButton, { paddingHorizontal: 20, paddingVertical: 20, justifyContent: 'space-between', marginBottom: 15 }]} onPress={() => this.props.navigation.navigate('SelectAutomazione')}>
                                <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                                    <Image source={require('../assets/impostazioni.png')} resizeMode='contain' style={{ width: 50, height: 50 }} />
                                    <View style={{ marginLeft: 15 }}>
                                        <Text style={{ fontFamily: 'DM-Medium', fontSize: 16 }}>Modifica messaggi</Text>
                                        <Text style={{ fontFamily: 'DM-Regular', fontSize: 12, color: '#6d6e72' }}>Gestisci automazioni</Text>
                                    </View>
                                </View>
                                <Feather name='arrow-right-circle' color='#0070FF' size={25} />
                            </TouchableOpacity>
                            <TouchableOpacity style={[styles.buttonLink, styles.responsiveCallButton, { paddingHorizontal: 20, paddingVertical: 20, justifyContent: 'space-between', marginBottom: 15 }]} onPress={() => this.props.navigation.navigate('Tutorial')}>
                                <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                                    <Image source={require('../assets/tutorial.png')} resizeMode='contain' style={{ width: 50, height: 50 }} />
                                    <View style={{ marginLeft: 15 }}>
                                        <Text style={{ fontFamily: 'DM-Medium', fontSize: 16 }}>Tutorial</Text>
                                        <Text style={{ fontFamily: 'DM-Regular', fontSize: 12, color: '#6d6e72' }}>Documentazione completa</Text>
                                    </View>
                                </View>
                                <Feather name='arrow-right-circle' color='#0070FF' size={25} />
                            </TouchableOpacity>
                        </View>
                        {/*(this.state.preventiviInAttesa > 0 && this.state.isActiveStatistic == true)
                            &&
                            <>
                            <Text style={{ fontFamily: 'DM-Bold', fontSize: 20, color: '#FFF' }}>Preventivi in attesa</Text>
                            <View style={{ marginVertical: (Dimensions.get('screen').width <= 500) ? 20 : 80 }}>
                                <TouchableOpacity style={[styles.buttonLink, styles.responsiveCall, { paddingHorizontal: 20, paddingVertical: 20 }]} onPress={() => this.props.navigation.navigate('Statistiche', { type: 2 })}>
                                    <Feather name='alert-triangle' color='#b08d00' size={30} />
                                    <View style={{ marginLeft: 15 }}>
                                        <Text style={{ fontFamily: 'DM-Medium', fontSize: 16 }}>Hai {this.state.preventiviInAttesa} preventivi in attesa</Text>
                                        <Text style={{ fontFamily: 'DM-Regular', fontSize: 12, color: '#6d6e72' }}>Guarda i report</Text>
                                    </View>
                                </TouchableOpacity>
                            </View>
                            </>
                        */}
                        {/*(this.state.isProva == 1 && this.state.emailTest !== 'icloud.com' && this.state.idUtente !== 16 && this.state.idUtente !== '16')
                            &&
                            <>
                            <Text style={{ fontFamily: 'DM-Bold', fontSize: 20 }}>Il tuo piano</Text>
                            <View style={{ marginVertical: 20 }}>
                                <View style={[styles.buttonLink, { width: Dimensions.get('screen').width - 40 }]}>
                                    <Image source={require('../assets/account.png')} resizeMode='contain' style={{ width: 150, height: 130 }} />
                                    <View style={{ marginLeft: 14 }}>
                                        <View style={styles.badge}>
                                            <Text style={{ fontFamily: 'DM-Medium', fontSize: 12, color: '#fff' }}>La versione demo scade</Text>
                                        </View>
                                        <Text style={{ fontFamily: 'DM-Medium', fontSize: 14 }}>Il giorno {Moment(this.state.dataScadenza).format('DD/MM/YYYY')}{'\n'}alle {Moment(this.state.dataScadenza).format('HH:mm')}</Text>
                                        <Text style={{ fontFamily: 'DM-Regular', fontSize: 11, color: '#6d6e72' }}>Non appena acquisti la{'\n'}versione premium avrai{'\n'}l'intero accesso{'\n'}alla piattaforma</Text>
                                    </View>
                                </View>
                            </View>
                            </>
                        */}
                    </View>
                </ScrollView>
                <View style={styles.positionCricle}>
                    <View style={styles.circle}></View>
                </View>
                {/*<View style={{
                    position: 'absolute',
                    bottom: 0,
                    right: 0,
                    zIndex: 2
                }}>
                    <View style={[styles.buttonLink, {
                        borderRadius: 0,
                        borderTopLeftRadius: 20,
                        justifyContent: 'center',
                        width: 'auto',
                        paddingHorizontal: 15,
                        backgroundColor: '#fae2cf'
                    }]}>
                        <Text style={{ fontFamily: 'DM-Medium', fontSize: 13 }}>Vers 2.0 BETA</Text>
                    </View>
                </View>*/}
            </View>
        )
    }
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: '#2484FF'
    },
    button: {
        backgroundColor: '#fff',
        borderRadius: 10,
        width: '100%',
        paddingHorizontal: 20,
        paddingVertical: 15,
        alignItems: 'center'
    },
    backButton: {
        backgroundColor: '#2484FF',
        width: 'auto',
        height: 50,
        borderRadius: 10,
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        paddingHorizontal: 15
    },
    header: {
        paddingHorizontal: 20,
        paddingTop: 10 + Constants.statusBarHeight,
        paddingBottom: 10,
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    containerLink: {
        paddingTop: 10,
        paddingHorizontal: (Dimensions.get('screen').width <= 500) ? 20 : 150,
        paddingBottom: 30
    },
    buttonLink: {
        backgroundColor: '#fff',
        borderRadius: 10,
        flexDirection: 'row',
        alignItems: 'center',
        paddingHorizontal: 5,
        paddingVertical: 13,
        width: (Dimensions.get('screen').width / 2) - 30,
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 1,
        },
        shadowOpacity: 0.22,
        shadowRadius: 2.22,
        elevation: 3,
    },
    badge: {
        backgroundColor: '#b08d00',
        paddingHorizontal: 6,
        paddingVertical: 3,
        borderRadius: 10,
        marginBottom: 6
    },
    backgroundImage: {
        backgroundColor: '#0795FF',
        height: 140,
        width: Dimensions.get('screen').width,
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        zIndex: -1,
        shadowColor: "#000"
    },
    backgroundRounde: {
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#fff',
        width: 280,
        height: 280,
        borderRadius: 140,
        shadowOffset: {
            width: 0,
            height: 2,
        },
        shadowOpacity: 0.25,
        shadowRadius: 3.84,
        elevation: 5
    },
    buttonMore: {
        backgroundColor: '#0795FF',
        alignItems: 'center',
        justifyContent: 'center',
        paddingVertical: 5,
        paddingHorizontal: 13,
        borderRadius: 18,
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 1,
        },
        shadowOpacity: 0.22,
        shadowRadius: 2.22,
        elevation: 3,
    },
    positionCricle: {
        position: 'absolute',
        bottom: - Dimensions.get('screen').width / 4,
        left: - Dimensions.get('screen').width / 5,
        zIndex: -1
    },
    circle: {
        backgroundColor: '#0070FF',
        width: Dimensions.get('screen').width / 2,
        height: Dimensions.get('screen').width / 2,
        borderRadius: (Dimensions.get('screen').width / 2) / 2,
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 1,
        },
        shadowOpacity: 0.22,
        shadowRadius: 2.22,
        elevation: 3,
    },
    headerHome: {
        backgroundColor: '#0070FF',
        width: Dimensions.get('screen').width + 10,
        marginLeft: -5,
        borderColor: '#f8d54c',
        borderBottomWidth: 5,
        borderLeftWidth: 5,
        borderRightWidth: 5,
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 1,
        },
        shadowOpacity: 0.22,
        shadowRadius: 2.22,
        elevation: 3,
        borderBottomLeftRadius: 20,
        borderBottomRightRadius: 20
    },
    responsiveCall: {
        width: (Dimensions.get('screen').width <= 500) ? Dimensions.get('screen').width - 40 : '30%'
    },
    responsiveCallButton: {
        width: (Dimensions.get('screen').width <= 500) ? Dimensions.get('screen').width - 40 : '47%',
        marginTop: 0
    }
});