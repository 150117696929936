import React from 'react';
import { StyleSheet, Text, View, StatusBar, Dimensions, Image, ScrollView, Modal, Alert, TouchableOpacity as TouchButton, Switch, Platform, TouchableOpacity, TextInput } from 'react-native';
import { ActivityIndicator } from 'react-native-paper';
import Constants from 'expo-constants';
import { AntDesign, Feather } from '@expo/vector-icons';
import { Table, Row, Rows } from 'react-native-table-component-2';
import * as Crypto from 'expo-crypto';
import AsyncStorage from '@react-native-async-storage/async-storage';
import RNPickerSelect from 'react-native-picker-select';
//import DateTimePickerModal from "react-native-modal-datetime-picker";
import DateTimePicker from '@react-native-community/datetimepicker';
import Moment from 'moment';
import 'moment/locale/it';

export default class VisiteUtenteScreen extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            visite: [],
            visiteHistory: [],
            search: '',
            valore: null,
            denteMancante: false,
            unaCarie: false,
            parodontite: false,
            analisiOrtodonzia: false,
            analisiGnatologica: false,
            showFilter: false,
            isFiltri: false,
            idPaziente: this.props.route.params.idPaziente,
            desideriPaziente: null,
            sesso: null,
            protesiMobile: false,
            showFromDate: false,
            showToDate: false,
            fromDate: new Date(),
            toDate: new Date(),
            isDateSelect: false
        }
    }

    componentDidMount() {
        this.getListofData()
    }

    getListofData = async () => {
        let token = await Crypto.digestStringAsync(Crypto.CryptoDigestAlgorithm.MD5, '8Hsh-zjh83sdhy83Hbz')
        let id = await AsyncStorage.getItem('id')
        let formData = new FormData();
        formData.append('token', token);
        formData.append('id_user', id);
        formData.append('id_paziente', this.state.idPaziente);
        fetch(`https://clinicautomation.it/app/visite_utente.php`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'multipart/form-data'
            },
            body: formData
        })
        .then((response) => response.json())
        .then((json) => {
            this.setState({ visite: json, visiteHistory: json })
        }).catch((error) => {
            console.error(error);
        })
    }

    addFilters = async () => {
        if (this.state.valore != null || this.state.denteMancante != false || this.state.unaCarie != false || this.state.parodontite != false || this.state.analisiGnatologica != false || this.state.analisiOrtodonzia != false || this.state.desideriPaziente != null || this.state.sesso != null || this.state.protesiMobile != false || this.state.isDateSelect != false) {
            let token = await Crypto.digestStringAsync(Crypto.CryptoDigestAlgorithm.MD5, '8Hsh-zjh83sdhy83Hbz')
            let id = await AsyncStorage.getItem('id')
            let formData = new FormData();
            formData.append('token', token);
            formData.append('id_user', id);
            formData.append('id_paziente', this.state.idPaziente);
            formData.append('valore', this.state.valore);
            formData.append('denti', this.state.denteMancante);
            formData.append('carie', this.state.unaCarie);
            formData.append('parodontite', this.state.parodontite);
            formData.append('ortodonzia', this.state.analisiOrtodonzia);
            formData.append('gnatologica', this.state.analisiGnatologica);
            formData.append('desideri_paziente', this.state.desideriPaziente);
            formData.append('sesso', this.state.sesso);
            formData.append('protesi_mobile', this.state.protesiMobile);
            fetch(`https://clinicautomation.it/app/filtri_visite_paziente.php`, {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'multipart/form-data'
                },
                body: formData
            })
            .then((response) => response.json())
            .then((json) => {
                this.setState({ visite: json, showFilter: false, isFiltri: true })
                if (this.state.isDateSelect) {
                    let filterDate = this.state.visite.filter(x => Moment(x.data_creazione).format('YYYY-MM-DD') >= Moment(this.state.fromDate).format('YYYY-MM-DD') && Moment(x.data_creazione).format('YYYY-MM-DD') <= Moment(this.state.toDate).format('YYYY-MM-DD'))
                    this.setState({ visite: filterDate })
                }
            }).catch((error) => {
                console.error(error);
            })
        } else {
            Alert.alert(
                "Attenzione",
                "Per aggiungere un filtro devi prima selezionare uno dei due valori",
                [
                    { text: "OK" }
                ]
            )
        }
    }

    removeFilters = () => {
        this.setState({ visite: this.state.visiteHistory, showFilter: false, valore: null, denteMancante: false, unaCarie: false, parodontite: false, analisiGnatologica: false, analisiOrtodonzia: false, desideriPaziente: null, sesso: null, protesiMobile: false, fromDate: new Date(), toDate: new Date(), isDateSelect: false, isFiltri: false })
    }

    setFromDate = (event, date) => {
        if (event.type == 'dismissed') {
            this.setState({ showFromDate: false })
        } else {
            this.setState({ fromDate: date, isDateSelect: true, showFromDate: false })
        }
    }

    setToDate = (event, date) => {
        if (event.type == 'dismissed') {
            this.setState({ showToDate: false })
        } else {
            this.setState({ toDate: date, isDateSelect: true, showToDate: false })
        }
    }

    render() {
        return(
            <View style={styles.container}>
                <StatusBar translucent={true} barStyle='light-content' backgroundColor='transparent' />
                <View style={{ backgroundColor: '#0795FF' }}>
                    <View style={styles.header}>
                        <TouchableOpacity style={styles.backButton} onPress={() => this.props.navigation.goBack()}>
                            <Feather name='arrow-left' color='#fff' size={23} />
                            <Text style={{ fontFamily: 'DM-Medium', fontSize: 14, color: '#fff', marginLeft: 6 }}>Visite Utente</Text>
                        </TouchableOpacity>
                    </View>
                </View>
                <View style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end', backgroundColor: '#f7f1e6', paddingHorizontal: 20, paddingVertical: 15 }}>
                    <TouchableOpacity style={[styles.button, { flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }]} onPress={() => this.setState({ showFilter: true })}>
                        {(this.state.isFiltri)
                            &&
                            <AntDesign name='checkcircle' color='#fff' size={20} style={{ marginRight: 6 }} />
                        }
                        <Text style={{ fontFamily: 'DM-Bold', fontSize: 15, color: '#fff' }}>Filtri</Text>
                    </TouchableOpacity>
                </View>
                <View style={styles.containerLink}>
                    <View style={[styles.head, { flexDirection: 'row', alignItems: 'center', paddingHorizontal: 0 }]}>
                        <View style={styles.widthTable}>
                            <Text style={[styles.text, { fontFamily: 'DM-Medium', color: '#fff', marginLeft: 10 }]}>Nome</Text>
                        </View>
                        <View style={styles.widthTable}>
                            <Text style={[styles.text, { fontFamily: 'DM-Medium', color: '#fff' }]}>Cognome</Text>
                        </View>
                        <View style={styles.widthTable}>
                            <Text style={[styles.text, { fontFamily: 'DM-Medium', color: '#fff', marginRight: 10 }]}>Esito</Text>
                        </View>
                    </View>
                    <ScrollView showsVerticalScrollIndicator={false}>
                        <View style={{ marginBottom: Dimensions.get('screen').height / 3 }}>
                            {this.state.visite.map((item) => {
                                return(
                                    <TouchableOpacity
                                        key={item.id}
                                        style={{ borderBottomWidth: 1, borderColor: '#ededed', backgroundColor: '#fff', flexDirection: 'row', alignItems: 'center' }}
                                        onPress={() => this.props.navigation.navigate('VisitaModifica', {
                                            id: item.id,
                                            user: item.id_paziente,
                                            desideri: item.desideri,
                                            fuma: item.fuma,
                                            ipertensione: item.ipertensione,
                                            diabete: item.diabete,
                                            osteoporosi: item.osteoporosi,
                                            tiroidite: item.tiroidite,
                                            malattie_cardiache: item.malattie_cardiache,
                                            allergie: item.allergie,
                                            in_gravidanza: item.in_gravidanza,
                                            malattie_virali: item.malattie_virali,
                                            foto_sorriso: item.foto_sorriso,
                                            foto_intraorale_inferiore: item.foto_intraorale_inferiore,
                                            foto_intraorale_superiore: item.foto_intraorale_superiore,
                                            foto_sorriso_profilo_destro: item.foto_sorriso_profilo_destro,
                                            foto_sorriso_profilo_sinistro: item.foto_sorriso_profilo_sinistro,
                                            panoramica: item.panoramica,
                                            bitewing_destro: item.bitewing_destro,
                                            bitewing_sinistro: item.bitewing_sinistro,
                                            denti_mancanti_superiore: item.denti_mancanti_superiore,
                                            denti_mancanti_inferiore: item.denti_mancanti_inferiore,
                                            edentulia_totale_superiore: item.edentulia_totale_superiore,
                                            edentulia_totale_inferiore: item.edentulia_totale_inferiore,
                                            otturazioni_superiore: item.otturazioni_superiore,
                                            otturazioni_inferiore: item.otturazioni_inferiore,
                                            carie_superiore: item.carie_superiore,
                                            carie_inferiore: item.carie_inferiore,
                                            tasche_superiore: item.tasche_superiore,
                                            tasche_inferiore: item.tasche_inferiore,
                                            forcazioni_superiore: item.forcazioni_superiore,
                                            forcazioni_inferiore: item.forcazioni_inferiore,
                                            restauri_protesi_superiore: item.restauri_protesi_superiore,
                                            restauri_protesi_inferiore: item.restauri_protesi_inferiore,
                                            igene_orale: item.igene_orale,
                                            denti_mobili: item.denti_mobili,
                                            protesi_mobile: item.protesi_mobile,
                                            sanguinamento_gengive: item.sanguinamento_gengive,
                                            placca: item.placca,
                                            atrofia_ossea: item.atrofia_ossea,
                                            parodontite: item.parodontite,
                                            analisi_ortodonzia: item.analisi_ortodonzia,
                                            analisi_gnatologica: item.analisi_gnatologica,
                                            aree_intervento: item.aree_intervento,
                                            diario_clinico: item.diario_clinico,
                                            preventivo: item.preventivo,
                                            esito: item.esito,
                                            valore: item.valore,
                                            altre_proposte: item.altre_proposte,
                                            risoluzione_problemi: item.risoluzione_problemi,
                                            foto_chart: item.foto_chart,
                                            malattie_sistemiche: item.malattie_sistemiche,
                                            stile_vita: item.stile_vita
                                        })}
                                    >
                                        <View style={styles.widthRow}>
                                            <Text style={[styles.text, { fontFamily: 'DM-Regular', marginLeft: 10 }]}>{item.nome}</Text>
                                        </View>
                                        <View style={styles.widthRow}>
                                            <Text style={[styles.text, { fontFamily: 'DM-Regular' }]}>{item.cognome}</Text>
                                        </View>
                                        <View style={styles.widthRow}>
                                            <Text style={[styles.text, { fontFamily: 'DM-Regular', marginRight: 10, color: (item.esito == 'Accettato') ? 'green' : (item.esito == 'Rifiutato') ? 'red' : (item.esito == 'In attesa') ? 'orange' : '#000' }]}>
                                                {(item.esito != 'null' && item.esito != null) ? item.esito : ''}
                                            </Text>
                                        </View>
                                    </TouchableOpacity>
                                )
                            })}
                        </View>
                    </ScrollView>
                </View>
                <Modal
                    animationType='slide'
                    presentationStyle='pageSheet'
                    visible={this.state.showFilter}
                    onRequestClose={() => {
                        this.setState({ showFilter: false })
                    }}
                >
                    <View style={{ marginTop: 40, paddingHorizontal: 30 }}>
                        <View style={{ justifyContent: 'space-between', flexDirection: 'row', alignItems: 'center', marginBottom: 15 }}>
                            <View style={[styles.backButtonModal, { width: 'auto', paddingHorizontal: 20 }]}>
                                <Text style={{ fontFamily: 'DM-Medium', fontSize: 16 }}>Filtri Visite</Text>
                            </View>
                            {(Platform.OS == 'android')
                                ?
                                <TouchButton style={styles.backButtonModal} onPress={() => this.setState({ showFilter: false })}>
                                    <AntDesign name='closecircleo' color='#000' size={23} />
                                </TouchButton>
                                :
                                <TouchableOpacity style={styles.backButtonModal} onPress={() => this.setState({ showFilter: false })}>
                                    <AntDesign name='closecircleo' color='#000' size={23} />
                                </TouchableOpacity>
                            }
                        </View>
                        <ScrollView showsVerticalScrollIndicator={false}>
                        <View style={{ paddingTop: 35, paddingBottom: Dimensions.get('screen').height / 3 }}>
                        <View style={{ marginBottom: 20 }}>
                            <Text style={{ fontFamily: 'DM-Medium', fontSize: 16, marginBottom: 10 }}>Intervallo date</Text>
                            {(Platform.OS == 'android')
                                ?
                                <View style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                                    <View style={{ width: (Dimensions.get('screen').width / 2) - 35 }}>
                                        <Text style={{ fontFamily: 'DM-Regular', fontSize: 14, marginBottom: 10 }}>Da</Text>
                                        <TouchButton style={styles.buttonDate} onPress={() => this.setState({ showFromDate: true })}>
                                            <Text style={{ fontFamily: 'DM-Medium', fontSize: 17 }}>{Moment(this.state.fromDate).format('DD/MM/YYYY')}</Text>
                                        </TouchButton>
                                    </View>
                                    <View style={{ width: (Dimensions.get('screen').width / 2) - 35 }}>
                                        <Text style={{ fontFamily: 'DM-Regular', fontSize: 14, marginBottom: 10 }}>A</Text>
                                        <TouchButton style={styles.buttonDate} onPress={() => this.setState({ showToDate: true })}>
                                            <Text style={{ fontFamily: 'DM-Medium', fontSize: 17 }}>{Moment(this.state.toDate).format('DD/MM/YYYY')}</Text>
                                        </TouchButton>
                                    </View>
                                </View>
                                :
                                <View style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                                    <View style={{ width: (Dimensions.get('screen').width / 2) - 35 }}>
                                        <Text style={{ fontFamily: 'DM-Regular', fontSize: 14, marginBottom: 10 }}>Da</Text>
                                        <TouchableOpacity style={styles.buttonDate} onPress={() => this.setState({ showFromDate: true })}>
                                            <Text style={{ fontFamily: 'DM-Medium', fontSize: 17 }}>{Moment(this.state.fromDate).format('DD/MM/YYYY')}</Text>
                                        </TouchableOpacity>
                                    </View>
                                    <View style={{ width: (Dimensions.get('screen').width / 2) - 35 }}>
                                        <Text style={{ fontFamily: 'DM-Regular', fontSize: 14, marginBottom: 10 }}>A</Text>
                                        <TouchableOpacity style={styles.buttonDate} onPress={() => this.setState({ showToDate: true })}>
                                            <Text style={{ fontFamily: 'DM-Medium', fontSize: 17 }}>{Moment(this.state.toDate).format('DD/MM/YYYY')}</Text>
                                        </TouchableOpacity>
                                    </View>
                                </View>
                            }
                        </View>
                        <View style={{ marginBottom: 20 }}>
                            <Text style={{ fontFamily: 'DM-Medium', fontSize: 16, marginBottom: 10 }}>Valore</Text>
                            <RNPickerSelect
                                placeholder={{ label: "Seleziona il valore", value: null }}
                                doneText='Fine'
                                value={this.state.valore}
                                style={{ viewContainer: [styles.inputSelect, { marginBottom: 0, justifyContent: 'center' }], inputIOS: { fontFamily: 'DM-Regular', fontSize: 16 }, inputAndroid: { fontFamily: 'DM-Regular', fontSize: 16, color: '#000' } }}
                                onValueChange={(value) => this.setState({ valore: value })}
                                items={[
                                    { label: 'Fino a 1k', value: 'Fino a 1k' },
                                    { label: 'Da 1 a 3k', value: 'Da 1 a 3k' },
                                    { label: 'Da 3 a 5k', value: 'Da 3 a 5k' },
                                    { label: 'Oltre i 5k', value: 'Oltre i 5k' }
                                ]}
                            />
                        </View>
                        <View style={{ marginBottom: 20 }}>
                            <Text style={{ fontFamily: 'DM-Medium', fontSize: 16, marginBottom: 10 }}>Desideri del paziente</Text>
                            <RNPickerSelect
                                placeholder={{ label: 'Seleziona il valore', value: null }}
                                doneText='Fine'
                                value={this.state.desideriPaziente}
                                style={{ viewContainer: [styles.inputSelect, { marginBottom: 0, justifyContent: 'center' }], inputIOS: { fontFamily: 'DM-Regular', fontSize: 16 }, inputAndroid: { fontFamily: 'DM-Regular', fontSize: 16, color: '#000' } }}
                                onValueChange={(value) => this.setState({ desideriPaziente: value })}
                                items={[
                                    { label: 'Sorriso fisso', value: 'Sorriso fisso' },
                                    { label: 'Masticare', value: 'Masticare' },
                                    { label: 'Denti più luminosi', value: 'Denti più luminosi' },
                                    { label: 'Denti da star', value: 'Denti da star' },
                                    { label: 'Non più dolore', value: 'Non più dolore' },
                                ]}
                            />
                        </View>
                        <View style={{ marginBottom: 20 }}>
                            <Text style={{ fontFamily: 'DM-Medium', fontSize: 16, marginBottom: 10 }}>Sesso</Text>
                            <RNPickerSelect
                                placeholder={{ label: 'Seleziona il tipo di Sesso', value: null }}
                                doneText='Fine'
                                value={this.state.sesso}
                                style={{ viewContainer: [styles.inputSelect, { marginBottom: 0, justifyContent: 'center' }], inputIOS: { fontFamily: 'DM-Regular', fontSize: 16 }, inputAndroid: { fontFamily: 'DM-Regular', fontSize: 16, color: '#000' } }}
                                onValueChange={(sesso) => this.setState({ sesso })}
                                items={[
                                    { label: 'Uomo', value: 'Uomo' },
                                    { label: 'Donna', value: 'Donna' },
                                ]}
                            />
                        </View>
                        <View style={{ marginBottom: 20, flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                            <Text style={{ fontFamily: 'DM-Medium', fontSize: 16 }}>Pazienti con Parodontite</Text>
                            <Switch
                                trackColor={{ false: "#f5f5f5", true: "#0795FF" }}
                                thumbColor={this.state.parodontite ? '#fff' : '#fff'}
                                ios_backgroundColor="#f5f5f5"
                                onValueChange={() => this.setState({ parodontite: !this.state.parodontite })}
                                value={this.state.parodontite}
                            />
                        </View>
                        <View style={{ marginBottom: 20, flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                            <Text style={{ fontFamily: 'DM-Medium', fontSize: 16 }}>Pazienti con Protesi mobile</Text>
                            <Switch
                                trackColor={{ false: "#f5f5f5", true: "#0795FF" }}
                                thumbColor={this.state.protesiMobile ? '#fff' : '#fff'}
                                ios_backgroundColor="#f5f5f5"
                                onValueChange={() => this.setState({ protesiMobile: !this.state.protesiMobile })}
                                value={this.state.protesiMobile}
                            />
                        </View>
                        <View style={{ marginBottom: 20, flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                            <Text style={{ fontFamily: 'DM-Medium', fontSize: 16 }}>Pazienti con almeno{'\n'}un Dente mancante</Text>
                            <Switch
                                trackColor={{ false: "#f5f5f5", true: "#0795FF" }}
                                thumbColor={this.state.denteMancante ? '#fff' : '#fff'}
                                ios_backgroundColor="#f5f5f5"
                                onValueChange={() => this.setState({ denteMancante: !this.state.denteMancante })}
                                value={this.state.denteMancante}
                            />
                        </View>
                        <View style={{ marginBottom: 20, flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                            <Text style={{ fontFamily: 'DM-Medium', fontSize: 16 }}>Pazienti con almeno{'\n'}una Carie</Text>
                            <Switch
                                trackColor={{ false: "#f5f5f5", true: "#0795FF" }}
                                thumbColor={this.state.unaCarie ? '#fff' : '#fff'}
                                ios_backgroundColor="#f5f5f5"
                                onValueChange={() => this.setState({ unaCarie: !this.state.unaCarie })}
                                value={this.state.unaCarie}
                            />
                        </View>
                        <View style={{ marginBottom: 20, flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                            <Text style={{ fontFamily: 'DM-Medium', fontSize: 16 }}>Pazienti con problemi{'\n'}Ortodontici</Text>
                            <Switch
                                trackColor={{ false: "#f5f5f5", true: "#0795FF" }}
                                thumbColor={this.state.analisiOrtodonzia ? '#fff' : '#fff'}
                                ios_backgroundColor="#f5f5f5"
                                onValueChange={() => this.setState({ analisiOrtodonzia: !this.state.analisiOrtodonzia })}
                                value={this.state.analisiOrtodonzia}
                            />
                        </View>
                        <View style={{ marginBottom: 20, flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                            <Text style={{ fontFamily: 'DM-Medium', fontSize: 16 }}>Pazienti con problemi{'\n'}Gnatologici</Text>
                            <Switch
                                trackColor={{ false: "#f5f5f5", true: "#0795FF" }}
                                thumbColor={this.state.analisiGnatologica ? '#fff' : '#fff'}
                                ios_backgroundColor="#f5f5f5"
                                onValueChange={() => this.setState({ analisiGnatologica: !this.state.analisiGnatologica })}
                                value={this.state.analisiGnatologica}
                            />
                        </View>
                        {(this.state.isFiltri)
                            ?
                            <View style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                                {(Platform.OS == 'android')
                                    ?
                                    <>
                                    <TouchButton onPress={this.removeFilters} style={[styles.buttonModal, { backgroundColor: '#f5f5f5', paddingVertical: 18, width: (Dimensions.get('screen').width / 3) - 35 }]}>
                                        <Text style={{ fontFamily: 'DM-Bold', fontSize: 13 }}>Rimuovi</Text>
                                    </TouchButton>
                                    <TouchButton onPress={this.addFilters} style={[styles.buttonModal, { paddingVertical: 16, width: (Dimensions.get('screen').width - 65) - ((Dimensions.get('screen').width / 3) - 35) }]}>
                                        <Text style={{ fontFamily: 'DM-Bold', fontSize: 15, color: '#fff' }}>Aggiungi Filtri</Text>
                                    </TouchButton>
                                    </>
                                    :
                                    <>
                                    <TouchableOpacity onPress={this.removeFilters} style={[styles.buttonModal, { backgroundColor: '#f5f5f5', paddingVertical: 18, width: (Dimensions.get('screen').width / 3) - 35 }]}>
                                        <Text style={{ fontFamily: 'DM-Bold', fontSize: 13 }}>Rimuovi</Text>
                                    </TouchableOpacity>
                                    <TouchableOpacity onPress={this.addFilters} style={[styles.buttonModal, { paddingVertical: 16, width: (Dimensions.get('screen').width - 65) - ((Dimensions.get('screen').width / 3) - 35) }]}>
                                        <Text style={{ fontFamily: 'DM-Bold', fontSize: 15, color: '#fff' }}>Aggiungi Filtri</Text>
                                    </TouchableOpacity>
                                    </>
                                }
                            </View>
                            :
                            (Platform.OS == 'android')
                                ?
                                <TouchButton style={styles.buttonModal} onPress={this.addFilters}>
                                    <Text style={{ fontFamily: 'DM-Bold', fontSize: 18, color: '#fff' }}>Aggiungi Filtri</Text>
                                </TouchButton>
                                :
                                <TouchableOpacity style={styles.buttonModal} onPress={this.addFilters}>
                                    <Text style={{ fontFamily: 'DM-Bold', fontSize: 18, color: '#fff' }}>Aggiungi Filtri</Text>
                                </TouchableOpacity>
                        }
                        {(Platform.OS == 'android')
                            &&
                            <>
                            {(this.state.showFromDate)
                                &&
                                <DateTimePicker
                                    mode='date'
                                    value={this.state.fromDate}
                                    maximumDate={new Date()}
                                    locale='it_IT'
                                    onChange={this.setFromDate}
                                />
                            }
                            {(this.state.showToDate)
                                &&
                                <DateTimePicker
                                    mode='date'
                                    value={this.state.toDate}
                                    maximumDate={new Date()}
                                    minimumDate={this.state.fromDate}
                                    locale='it_IT'
                                    onChange={this.setToDate}
                                />
                            }
                            </>
                        }
                        {(Platform.OS != 'android')
                            &&
                            <>
                            {/* <DateTimePickerModal
                                isVisible={this.state.showFromDate}
                                mode="date"
                                display='inline'
                                date={this.state.fromDate}
                                maximumDate={new Date()}
                                locale='it_IT'
                                cancelTextIOS='Chiudi'
                                confirmTextIOS='Seleziona'
                                onConfirm={(date) => this.setState({ fromDate: date, isDateSelect: true, showFromDate: false })}
                                onCancel={() => this.setState({ showFromDate: false })}
                            />
                            <DateTimePickerModal
                                isVisible={this.state.showToDate}
                                mode="date"
                                display='inline'
                                date={this.state.toDate}
                                maximumDate={new Date()}
                                minimumDate={this.state.fromDate}
                                locale='it_IT'
                                cancelTextIOS='Chiudi'
                                confirmTextIOS='Seleziona'
                                onConfirm={(date) => this.setState({ toDate: date, isDateSelect: true, showToDate: false })}
                                onCancel={() => this.setState({ showToDate: false })}
                            /> */}
                            </>
                        }
                        </View>
                        </ScrollView>
                    </View>
                </Modal>
            </View>
        )
    }
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: '#edf7ff'
    },
    button: {
        backgroundColor: '#0795FF',
        borderRadius: 10,
        width: (Dimensions.get('screen').width - 50) - ((Dimensions.get('screen').width / 1.3) - 50),
        height: 45,
        paddingHorizontal: 10,
        paddingVertical: 6,
        alignItems: 'center',
        justifyContent: 'center'
    },
    backButton: {
        backgroundColor: '#36a9ff',
        width: 'auto',
        height: 50,
        borderRadius: 10,
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        paddingHorizontal: 15
    },
    header: {
        paddingHorizontal: 20,
        paddingTop: 10 + Constants.statusBarHeight,
        paddingBottom: 10,
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    containerLink: {
        paddingBottom: 30
    },
    buttonLink: {
        backgroundColor: '#fff',
        borderRadius: 10,
        flexDirection: 'row',
        alignItems: 'center',
        paddingHorizontal: 5,
        paddingVertical: 13,
        width: (Dimensions.get('screen').width / 2) - 30,
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 1,
        },
        shadowOpacity: 0.22,
        shadowRadius: 2.22,
        elevation: 3,
    },
    head: {
        height: 50,
        backgroundColor: '#0795FF',
        paddingHorizontal: 5
    },
    text: { 
        margin: 6 
    },
    input: {
        backgroundColor: '#fff',
        borderRadius: 10,
        width: (Dimensions.get('screen').width / 1.3) - 50,
        paddingHorizontal: 20,
        paddingVertical: 10,
        height: 45,
        fontFamily: 'DM-Regular',
        color: '#000',
        fontSize: 15,
        marginRight: 10
    },
    widthTable: {
        width: Dimensions.get('screen').width / 3
    },
    widthRow: {
        height: 40,
        width: Dimensions.get('screen').width / 3,
        justifyContent: 'center'
    },
    backButtonModal: {
        backgroundColor: '#f5f5f5',
        width: 50,
        height: 50,
        borderRadius: 10,
        justifyContent: 'center',
        alignItems: 'center'
    },
    buttonModal: {
        backgroundColor: '#0795FF',
        borderRadius: 10,
        width: '100%',
        paddingHorizontal: 20,
        paddingVertical: 15,
        alignItems: 'center'
    },
    inputSelect: {
        backgroundColor: '#f5f5f5',
        borderRadius: 10,
        width: '100%',
        paddingHorizontal: 20,
        paddingVertical: 10,
        height: 55,
        marginBottom: 20,
        fontFamily: 'DM-Regular',
        color: '#000',
        fontSize: 16
    },
    buttonAnalisi: {
        backgroundColor: '#fff',
        borderRadius: 10,
        width: '100%',
        paddingHorizontal: 20,
        paddingVertical: 15,
        alignItems: 'center'
    }
});