import React from 'react';
import { StyleSheet, Text, View, StatusBar, Dimensions, Image, ScrollView, Modal, Alert, TouchableOpacity as TouchButton , Platform, Switch, FlatList, TouchableOpacity, TextInput, RefreshControl } from 'react-native';
import { ActivityIndicator, Divider } from 'react-native-paper';
import Constants from 'expo-constants';
import { AntDesign, FontAwesome, Feather } from '@expo/vector-icons';
import { Table, Row, Rows, Cell, TableWrapper, Col } from 'react-native-table-component-2';
import * as Crypto from 'expo-crypto';
import AsyncStorage from '@react-native-async-storage/async-storage';
import RNPickerSelect from 'react-native-picker-select';
//import DateTimePickerModal from "react-native-modal-datetime-picker";
import DateTimePicker from '@react-native-community/datetimepicker';
import Moment from 'moment';
import 'moment/locale/it';
import * as DocumentPicker from 'expo-document-picker';
import * as Linking from 'expo-linking';
import {default as ModalDismiss} from 'expo-react-native-modalbox';
import CryptoJS from 'crypto-js';
import axios from 'axios';
import { MaskedTextInput } from "react-native-mask-text";

export default class DashboardScreen extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            typeFilter: 0,
            dateStart: '',
            dateEnd: '',
            showModal: false,
            isPersonalizzato: false,
            reloadPage: true,
            fonte: {
                fonte_affissioni: "0",
                fonte_facebook: "0",
                fonte_google: "0",
                fonte_passaparola: "0",
                fonte_radio: "0",
                fonte_stampa: "0",
                fonte_tv: "0"
            },
            prime_visite: {
                pv_offline: "0",
                pv_online: "0",
                pv_riferiti: "0",
            },
            commerciali: {
                accettato_acquisiti: "0",
                accettato_lead: "0",
                accettato_medio: "0",
                accettato_medio_acquisiti: "0",
                accettato_medio_online: "0",
                accettato_totale: "0",
                accettato_venditori: "0"
            }
        }
    }

    componentDidMount() {
        this.getFonti()
        this.getPrimeVisite()
        this.getCommerciali()
    }

    refresh = () => {
        this.getFonti()
        this.getPrimeVisite()
        this.getCommerciali()
    }

    handleChangeType = (type) => {
        if (type == 2) {
            this.setState({ typeFilter: type, showModal: true })
        } else {
            this.setState({ typeFilter: type })
            this.getFonti()
            this.getPrimeVisite()
            this.getCommerciali()
        }
    }

    handleSaveDatePersonal = () => {
        if (this.state.dateStart !== "" && this.state.dateEnd !== "") {
            this.setState({ isPersonalizzato: true, reloadPage: true, showModal: false })
            this.getFonti()
            this.getPrimeVisite()
            this.getCommerciali()
        } else {
            Alert.alert(
                'Attenzione',
                'Devi inserire una data di inizio ed una di fine',
                [
                    { text: 'Ok' }
                ]
            )
        }
    }

    getFonti = async () => {
        const id = await AsyncStorage.getItem('id')
        const token = CryptoJS.MD5('8Hsh-zjh83sdhy83Hbz').toString(CryptoJS.enc.Hex)
        let startDate;
        let endDate;
        if (this.state.typeFilter == 0) {
            this.setState({ reloadPage: true })
            startDate = Moment().startOf('month').format('YYYY-MM-DD');
            endDate = Moment().endOf('month').format('YYYY-MM-DD');
        } else if (this.state.typeFilter == 1) {
            this.setState({ reloadPage: true })
            startDate = Moment().startOf('month').subtract('60', 'days').format('YYYY-MM-DD');
            endDate = Moment().endOf('month').format('YYYY-MM-DD');
        } else if (this.state.typeFilter == 2 && this.state.isPersonalizzato == true) {
            startDate = Moment(new Date(`${this.state.dateStart.split('/')[2]}-${this.state.dateStart.split('/')[1]}-${this.state.dateStart.split('/')[0]}`)).format('YYYY-MM-DD');
            endDate = Moment(new Date(`${this.state.dateEnd.split('/')[2]}-${this.state.dateEnd.split('/')[1]}-${this.state.dateEnd.split('/')[0]}`)).format('YYYY-MM-DD')
        }
        let formData = new FormData();
        formData.append('token', token);
        formData.append('id_user', id);
        formData.append('date_start', startDate);
        formData.append('date_end', endDate);
        axios.post('https://clinicautomation.it/app/get_dash_fonte.php', formData, {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'multipart/form-data'
            }
        })
        .then(async (response) => {
            const json = response.data;
            if (json.code == true) {
                this.setState({ fonte: json, reloadPage: false })
            } else {
                this.setState({
                    fonte: {
                        fonte_affissioni: "0",
                        fonte_facebook: "0",
                        fonte_google: "0",
                        fonte_passaparola: "0",
                        fonte_radio: "0",
                        fonte_stampa: "0",
                        fonte_tv: "0"
                    },
                    reloadPage: false
                })
            }
        }).catch((error) => {
            console.error(error);
        })
    }

    getPrimeVisite = async () => {
        const id = await AsyncStorage.getItem('id')
        const token = CryptoJS.MD5('8Hsh-zjh83sdhy83Hbz').toString(CryptoJS.enc.Hex)
        let startDate;
        let endDate;
        if (this.state.typeFilter == 0) {
            this.setState({ reloadPage: true })
            startDate = Moment().startOf('month').format('YYYY-MM-DD');
            endDate = Moment().endOf('month').format('YYYY-MM-DD');
        } else if (this.state.typeFilter == 1) {
            this.setState({ reloadPage: true })
            startDate = Moment().startOf('month').subtract('60', 'days').format('YYYY-MM-DD');
            endDate = Moment().endOf('month').format('YYYY-MM-DD');
        } else if (this.state.typeFilter == 2 && this.state.isPersonalizzato == true) {
            startDate = Moment(new Date(`${this.state.dateStart.split('/')[2]}-${this.state.dateStart.split('/')[1]}-${this.state.dateStart.split('/')[0]}`)).format('YYYY-MM-DD');
            endDate = Moment(new Date(`${this.state.dateEnd.split('/')[2]}-${this.state.dateEnd.split('/')[1]}-${this.state.dateEnd.split('/')[0]}`)).format('YYYY-MM-DD')
        }
        let formData = new FormData();
        formData.append('token', token);
        formData.append('id_user', id);
        formData.append('date_start', startDate);
        formData.append('date_end', endDate);
        axios.post('https://clinicautomation.it/app/get_dash_prime_visite.php', formData, {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'multipart/form-data'
            }
        })
        .then(async (response) => {
            const json = response.data;
            if (json.code == true) {
                this.setState({ prime_visite: json, reloadPage: false })
            } else {
                this.setState({
                    prime_visite: {
                        pv_offline: "0",
                        pv_online: "0",
                        pv_riferiti: "0",
                    },
                    reloadPage: false
                })
            }
        }).catch((error) => {
            console.error(error);
        })
    }

    currencyFormat = (numr) => {
        const num = parseFloat(numr)
        const price = num.toFixed(2).replace('.',',')
        return price.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')
    }

    getCommerciali = async () => {
        const id = await AsyncStorage.getItem('id')
        const token = CryptoJS.MD5('8Hsh-zjh83sdhy83Hbz').toString(CryptoJS.enc.Hex)
        let startDate;
        let endDate;
        if (this.state.typeFilter == 0) {
            this.setState({ reloadPage: true })
            startDate = Moment().startOf('month').format('YYYY-MM-DD');
            endDate = Moment().endOf('month').format('YYYY-MM-DD');
        } else if (this.state.typeFilter == 1) {
            this.setState({ reloadPage: true })
            startDate = Moment().startOf('month').subtract('60', 'days').format('YYYY-MM-DD');
            endDate = Moment().endOf('month').format('YYYY-MM-DD');
        } else if (this.state.typeFilter == 2 && this.state.isPersonalizzato == true) {
            startDate = Moment(new Date(`${this.state.dateStart.split('/')[2]}-${this.state.dateStart.split('/')[1]}-${this.state.dateStart.split('/')[0]}`)).format('YYYY-MM-DD');
            endDate = Moment(new Date(`${this.state.dateEnd.split('/')[2]}-${this.state.dateEnd.split('/')[1]}-${this.state.dateEnd.split('/')[0]}`)).format('YYYY-MM-DD')
        }
        let formData = new FormData();
        formData.append('token', token);
        formData.append('id_user', id);
        formData.append('date_start', startDate);
        formData.append('date_end', endDate);
        axios.post('https://clinicautomation.it/app/get_dash_commerciali.php', formData, {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'multipart/form-data'
            }
        })
        .then(async (response) => {
            const json = response.data;
            if (json.code == true) {
                const accettato_lead = parseInt(json.accettato_lead) / parseInt(json.count_totale_acc) * 100;
                const accettato_acquisiti = parseInt(json.accettato_acquisiti) / parseInt(json.count_totale_acc) * 100;
                const accettato_venditori = parseInt(json.accettato_venditori) / parseInt(json.count_totale_acc) * 100;
                
                this.setState({
                    commerciali: {
                        accettato_acquisiti: (parseInt(json.accettato_acquisiti) == 0 && parseInt(json.count_totale_acc) == 0) ? "0" : accettato_acquisiti.toFixed(2),
                        accettato_lead: (parseInt(json.accettato_lead) == 0 && parseInt(json.count_totale_acc) == 0) ? "0" : accettato_lead.toFixed(2),
                        accettato_medio: this.currencyFormat(json.accettato_medio),
                        accettato_medio_acquisiti: this.currencyFormat(json.accettato_medio_acquisiti),
                        accettato_medio_online: this.currencyFormat(json.accettato_medio_online),
                        accettato_totale: this.currencyFormat(json.accettato_totale),
                        accettato_venditori: (parseInt(json.accettato_venditori) == 0 && parseInt(json.count_totale_acc) == 0) ? "0" : accettato_venditori.toFixed(2)
                    },
                    reloadPage: false
                })
            } else {
                this.setState({
                    commerciali: {
                        accettato_acquisiti: "0",
                        accettato_lead: "0",
                        accettato_medio: "0",
                        accettato_medio_acquisiti: "0",
                        accettato_medio_online: "0",
                        accettato_totale: "0",
                        accettato_venditori: "0"
                    },
                    reloadPage: false
                })
            }
        }).catch((error) => {
            console.error(error);
        })
    }
 
    render() {
        return(
            <View style={styles.container}>
                <StatusBar translucent={true} barStyle='light-content' backgroundColor='transparent' />
                <View style={{ backgroundColor: '#0070FF', flex: 1 }}>
                    <View style={styles.header} onLayout={this.onLayout}>
                        <Text style={{ fontFamily: 'DM-Bold', fontSize: 20, color: '#fff' }}>Dashboard</Text>
                        <View style={[styles.backButton, { backgroundColor: '#0070FF' }]}>
                            <AntDesign name='pluscircleo' color='#0070FF' size={23} />
                            <Text style={{ fontFamily: 'DM-Medium', fontSize: 14, color: '#0070FF', marginLeft: 7 }}>Dashboard</Text>
                        </View>
                    </View>
                    <View style={styles.containerLink}>
                        <View style={styles.rowTabs}>
                            <TouchableOpacity onPress={() => this.handleChangeType(0)} style={[styles.tab, { borderColor: (this.state.typeFilter == 0) ? '#2484FF' : 'lightgrey', borderRightWidth: 1, backgroundColor: (this.state.typeFilter == 0) ? '#2484FF' : '#FFFFFF' }]}>
                                <Text style={{ fontFamily: 'DM-Medium', fontSize: 14, color: (this.state.typeFilter == 0) ? '#FFFFFF' : '#000' }}>Mese</Text>
                            </TouchableOpacity>
                            <TouchableOpacity onPress={() => this.handleChangeType(1)} style={[styles.tab, { borderTopLeftRadius: 0, borderBottomLeftRadius: 0, borderColor: (this.state.typeFilter == 1) ? '#2484FF' : 'lightgrey', borderRightWidth: 1, backgroundColor: (this.state.typeFilter == 1) ? '#2484FF' : '#FFFFFF' }]}>
                                <Text style={{ fontFamily: 'DM-Medium', fontSize: 14, color: (this.state.typeFilter == 1) ? '#FFFFFF' : '#000' }}>Trimestre</Text>
                            </TouchableOpacity>
                            <TouchableOpacity onPress={() => this.handleChangeType(2)} style={[styles.tab, { borderTopLeftRadius: 0, borderBottomLeftRadius: 0, borderTopRightRadius: 6, borderBottomRightRadius: 6, backgroundColor: (this.state.typeFilter == 2) ? '#2484FF' : '#FFFFFF' }]}>
                                <Text style={{ fontFamily: 'DM-Medium', fontSize: 14, color: (this.state.typeFilter == 2) ? '#FFFFFF' : '#000' }}>{(this.state.isPersonalizzato) ? `${this.state.dateStart.split('/')[0]}/${this.state.dateStart.split('/')[1]}-${this.state.dateEnd.split('/')[0]}/${this.state.dateEnd.split('/')[1]}` : "Personalizzato"}</Text>
                            </TouchableOpacity>
                        </View>
                    </View>
                    <ScrollView
                        showsVerticalScrollIndicator={false}
                        refreshControl={<RefreshControl refreshing={this.state.reloadPage} onRefresh={this.refresh} colors={['#FFFFFF']} tintColor={'#FFFFFF'} />}
                    >
                        <View style={{ marginTop: 20, alignItems: 'center', marginBottom: (Dimensions.get('screen').width <= 500) ? 20 : 100 }}>
                            <View style={styles.buttonLink}>
                                <View style={styles.rowButtonLink}>
                                    <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                                        <Image source={require('../assets/fonti.png')} resizeMode='contain' style={{ width: 40, height: 40 }} />
                                        <View style={{ marginLeft: 15 }}>
                                            <Text style={{ fontFamily: 'DM-Medium', fontSize: 16 }}>Fonti</Text>
                                            <Text style={{ fontFamily: 'DM-Regular', fontSize: 12, color: '#6d6e72' }}>Mensile</Text>
                                        </View>
                                    </View>
                                </View>
                                <Divider />
                                <View style={{ marginTop: 10, flexDirection: 'row', alignItems: 'flex-start' }}>
                                    <View style={{ width: (Dimensions.get('screen').width - 40) / 2 }}>
                                        <Text style={{ fontFamily: 'DM-Medium', fontSize: 15, marginBottom: 5 }}><Text style={{ fontFamily: 'DM-Bold', color: '#0070FF' }}>{this.state.fonte.fonte_passaparola} </Text> Riferiti</Text>
                                        <Text style={{ fontFamily: 'DM-Medium', fontSize: 15, marginBottom: 5 }}><Text style={{ fontFamily: 'DM-Bold', color: '#0070FF' }}>{this.state.fonte.fonte_facebook} </Text> Facebook</Text>
                                        <Text style={{ fontFamily: 'DM-Medium', fontSize: 15, marginBottom: 5 }}><Text style={{ fontFamily: 'DM-Bold', color: '#0070FF' }}>{this.state.fonte.fonte_stampa} </Text> Stampa</Text>
                                        <Text style={{ fontFamily: 'DM-Medium', fontSize: 15 }}><Text style={{ fontFamily: 'DM-Bold', color: '#0070FF' }}>{this.state.fonte.fonte_tv} </Text> Tv</Text>
                                    </View>
                                    <View style={{ width: (Dimensions.get('screen').width - 40) / 2 }}>
                                        <Text style={{ fontFamily: 'DM-Medium', fontSize: 15, marginBottom: 5 }}><Text style={{ fontFamily: 'DM-Bold', color: '#0070FF' }}>{this.state.fonte.fonte_google} </Text> Google</Text>
                                        <Text style={{ fontFamily: 'DM-Medium', fontSize: 15, marginBottom: 5 }}><Text style={{ fontFamily: 'DM-Bold', color: '#0070FF' }}>{this.state.fonte.fonte_affissioni} </Text> Affisioni</Text>
                                        <Text style={{ fontFamily: 'DM-Medium', fontSize: 15 }}><Text style={{ fontFamily: 'DM-Bold', color: '#0070FF' }}>{this.state.fonte.fonte_radio} </Text> Radio</Text>
                                    </View>
                                </View>
                            </View>
                            <View style={styles.buttonLink}>
                                <View style={styles.rowButtonLink}>
                                    <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                                        <Image source={require('../assets/prime-visite.png')} resizeMode='contain' style={{ width: 40, height: 40 }} />
                                        <View style={{ marginLeft: 15 }}>
                                            <Text style={{ fontFamily: 'DM-Medium', fontSize: 16 }}>Prime Visite</Text>
                                            <Text style={{ fontFamily: 'DM-Regular', fontSize: 12, color: '#6d6e72' }}>Mensile</Text>
                                        </View>
                                    </View>
                                </View>
                                <Divider />
                                <View style={{ marginTop: 10, flexDirection: 'row', alignItems: 'flex-start' }}>
                                    <View style={{ width: (Dimensions.get('screen').width - 40) / 2 }}>
                                        <Text style={{ fontFamily: 'DM-Medium', fontSize: 15, marginBottom: 5 }}><Text style={{ fontFamily: 'DM-Bold', color: '#0070FF' }}>{this.state.prime_visite.pv_riferiti} </Text> Pv Referral</Text>
                                        <Text style={{ fontFamily: 'DM-Medium', fontSize: 15 }}><Text style={{ fontFamily: 'DM-Bold', color: '#0070FF' }}>{this.state.prime_visite.pv_offline} </Text> Pv dall'offline</Text>
                                    </View>
                                    <View style={{ width: (Dimensions.get('screen').width - 40) / 2 }}>
                                        <Text style={{ fontFamily: 'DM-Medium', fontSize: 15 }}><Text style={{ fontFamily: 'DM-Bold', color: '#0070FF' }}>{this.state.prime_visite.pv_online} </Text> Pv dall'online</Text>
                                    </View>
                                </View>
                            </View>
                            <View style={styles.buttonLink}>
                                <View style={styles.rowButtonLink}>
                                    <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                                        <Image source={require('../assets/commerciali.png')} resizeMode='contain' style={{ width: 40, height: 40 }} />
                                        <View style={{ marginLeft: 15 }}>
                                            <Text style={{ fontFamily: 'DM-Medium', fontSize: 16 }}>Dati commerciali</Text>
                                            <Text style={{ fontFamily: 'DM-Regular', fontSize: 12, color: '#6d6e72' }}>Mensile</Text>
                                        </View>
                                    </View>
                                </View>
                                <Divider />
                                <View style={{ marginTop: 10, flexDirection: 'row', alignItems: 'flex-start' }}>
                                    <View style={{ width: Dimensions.get('screen').width - 40 }}>
                                        <Text style={{ fontFamily: 'DM-Medium', fontSize: 15, marginBottom: 5 }}><Text style={{ fontFamily: 'DM-Bold', color: '#0070FF' }}>€{this.state.commerciali.accettato_totale} </Text> Accettato totale</Text>
                                        <Text style={{ fontFamily: 'DM-Medium', fontSize: 15, marginBottom: 5 }}><Text style={{ fontFamily: 'DM-Bold', color: '#0070FF' }}>%{this.state.commerciali.accettato_lead} </Text> Accettato su lead</Text>
                                        <Text style={{ fontFamily: 'DM-Medium', fontSize: 15, marginBottom: 5 }}><Text style={{ fontFamily: 'DM-Bold', color: '#0070FF' }}>%{this.state.commerciali.accettato_acquisiti} </Text> Accettato su acquisiti</Text>
                                        <Text style={{ fontFamily: 'DM-Medium', fontSize: 15, marginBottom: 5 }}><Text style={{ fontFamily: 'DM-Bold', color: '#0070FF' }}>€{this.state.commerciali.accettato_medio} </Text> Accettato medio lead</Text>
                                        <Text style={{ fontFamily: 'DM-Medium', fontSize: 15, marginBottom: 5 }}><Text style={{ fontFamily: 'DM-Bold', color: '#0070FF' }}>€{this.state.commerciali.accettato_medio_online} </Text> Accettato medio online</Text>
                                        <Text style={{ fontFamily: 'DM-Medium', fontSize: 15, marginBottom: 5 }}><Text style={{ fontFamily: 'DM-Bold', color: '#0070FF' }}>€{this.state.commerciali.accettato_medio_acquisiti} </Text> Accettato medio acquisiti</Text>
                                        <Text style={{ fontFamily: 'DM-Medium', fontSize: 15 }}><Text style={{ fontFamily: 'DM-Bold', color: '#0070FF' }}>%{this.state.commerciali.accettato_venditori} </Text> Dati sui venditori</Text>
                                    </View>
                                </View>
                            </View>
                            {/*<View style={[styles.buttonLink, { width: (Dimensions.get('screen').width <= 500) ? Dimensions.get('screen').width - 40 : Dimensions.get('screen').width - 600, paddingHorizontal: 20, paddingVertical: 20, justifyContent: 'space-between', marginBottom: 20 }]}>
                                <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                                    <Image source={require('../assets/spesa.png')} resizeMode='contain' style={{ width: 40, height: 40 }} />
                                    <View style={{ marginLeft: 15 }}>
                                        <Text style={{ fontFamily: 'DM-Medium', fontSize: 16 }}>Spese marketing</Text>
                                        <Text style={{ fontFamily: 'DM-Regular', fontSize: 12, color: '#6d6e72' }}>Mensile</Text>
                                    </View>
                                </View>
                            </View>*/}
                        </View>
                    </ScrollView>
                </View>
                <ModalDismiss style={styles.modal} backdropOpacity={0.3} swipeToClose={true} backButtonClose={true} isOpen={this.state.showModal} onClosed={() => this.setState({showModal: false})} position={"center"} ref={"modal"}>
                    <Text style={{ fontFamily: 'DM-Bold', fontSize: 20, textAlign: 'center' }}>Seleziona le date</Text>
                    <View style={{ marginVertical: 20 }}>
                        <Text style={{ fontFamily: 'DM-Medium', fontSize: 16, marginBottom: 10 }}>Data inizio</Text>
                        <MaskedTextInput
                            mask="99/99/9999"
                            value={this.state.dateStart}
                            onChangeText={(dateStart) => this.setState({ dateStart })}
                            placeholderTextColor='#bfbfbf'
                            placeholder={`Es. ${Moment(new Date()).subtract(2, 'days').format('DD/MM/YYYY')}`}
                            style={styles.input}
                        />
                    </View>
                    <View>
                        <Text style={{ fontFamily: 'DM-Medium', fontSize: 16, marginBottom: 10 }}>Data fine</Text>
                        <MaskedTextInput
                            mask="99/99/9999"
                            value={this.state.dateEnd}
                            onChangeText={(dateEnd) => this.setState({ dateEnd })}
                            placeholderTextColor='#bfbfbf'
                            placeholder={`Es. ${Moment(new Date()).format('DD/MM/YYYY')}`}
                            style={styles.input}
                        />
                    </View>
                    <View style={{ alignItems: 'center' }}>
                        <TouchButton style={[styles.button, { width: (Dimensions.get('screen').width <= 500) ? '100%' : '40%' }]} onPress={this.handleSaveDatePersonal}>
                            <Text style={{ fontFamily: 'DM-Bold', fontSize: 18, color: '#fff' }}>
                                Salva
                            </Text>
                        </TouchButton>
                    </View>
                </ModalDismiss>
            </View>
        )
    }
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: '#0070FF'
    },
    button: {
        backgroundColor: '#0070FF',
        borderRadius: 10,
        width: '100%',
        paddingHorizontal: 20,
        paddingVertical: 15,
        alignItems: 'center',
        marginTop: 20
    },
    backButton: {
        backgroundColor: '#2484FF',
        width: 'auto',
        height: 50,
        borderRadius: 10,
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        paddingHorizontal: 15
    },
    header: {
        paddingHorizontal: 20,
        paddingTop: 10 + Constants.statusBarHeight,
        paddingBottom: 10,
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    containerLink: {
        paddingBottom: 10,
        alignItems: 'center'
    },
    buttonLink: {
        backgroundColor: '#fff',
        borderRadius: 10,
        width: (Dimensions.get('screen').width <= 500) ? Dimensions.get('screen').width - 40 : Dimensions.get('screen').width - 600,
        paddingHorizontal: 20,
        paddingVertical: 20,
        marginBottom: 20,
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 1,
        },
        shadowOpacity: 0.22,
        shadowRadius: 2.22,
        elevation: 3,
    },
    rowButtonLink: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginBottom: 15
    },
    head: {
        height: 50,
        backgroundColor: '#0070FF',
        paddingHorizontal: 5
    },
    text: { 
        marginVertical: 6 
    },
    input: {
        backgroundColor: '#f5f5f5',
        borderRadius: 10,
        width: '100%',
        paddingHorizontal: 20,
        paddingVertical: 10,
        height: 55,
        fontFamily: 'DM-Regular',
        color: '#000',
        fontSize: 16
    },
    inputSelect: {
        backgroundColor: '#f5f5f5',
        borderRadius: 10,
        width: '100%',
        paddingHorizontal: 20,
        paddingVertical: 10,
        height: 55,
        marginBottom: 20,
        fontFamily: 'DM-Regular',
        color: '#000',
        fontSize: 16
    },
    buttonDate: {
        backgroundColor: '#f5f5f5',
        borderRadius: 10,
        width: '100%',
        paddingHorizontal: 20,
        paddingVertical: 10,
        height: 55,
        justifyContent: 'center',
        alignItems: 'center'
    },
    widthTable: {
        width: Dimensions.get('screen').width / 3
    },
    widthRow: {
        height: 40,
        width: Dimensions.get('screen').width / 3,
        justifyContent: 'center'
    },
    backButtonModal: {
        backgroundColor: '#f5f5f5',
        width: 50,
        height: 50,
        borderRadius: 10,
        justifyContent: 'center',
        alignItems: 'center'
    },
    buttonModal: {
        backgroundColor: '#0070FF',
        borderRadius: 10,
        width: '100%',
        paddingHorizontal: 20,
        paddingVertical: 15,
        alignItems: 'center'
    },
    uploadContainer: {
        backgroundColor: '#2484FF',
        paddingHorizontal: 20,
        paddingVertical: 15,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    uploadButton: {
        backgroundColor: '#f5f5f5',
        width: '100%',
        height: 85,
        borderRadius: 10,
        borderColor: 'grey',
        borderWidth: 3,
        borderStyle: 'dashed',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: 10,
        marginBottom: 15
    },
    buttonRemoveFile: {
        backgroundColor: 'lightgrey',
        alignItems: 'center',
        justifyContent: 'center',
        paddingVertical: 4,
        paddingHorizontal: 10,
        borderRadius: 18,
        position: 'absolute',
        top: 18,
        right: 8,
        zIndex: 3
    },
    containerTime: {
        width: Dimensions.get('screen').width,
        paddingHorizontal: 30,
        paddingVertical: 20
    },
    headerLine: {
        backgroundColor: '#0070FF',
        borderTopLeftRadius: 15,
        borderTopRightRadius: 15,
        paddingHorizontal: 20,
        paddingVertical: 11.5
    },
    containerLine: {
        backgroundColor: '#2484FF',
        padding: 20,
        borderBottomLeftRadius: 15,
        borderBottomRightRadius: 15
    },
    lineItem: {
        backgroundColor: '#FFF',
        borderRadius: 10,
        paddingHorizontal: 12.5,
        paddingVertical: 16,
        shadowColor: "#00000085",
        shadowOffset: {
            width: 0,
            height: 2,
        },
        shadowOpacity: 0.25,
        shadowRadius: 3.84,
        elevation: 5,
        marginTop: 12,
        marginHorizontal: 5,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    rowTabs: {
        flexDirection: 'row',
        alignItems: 'center',
        paddingHorizontal: 20
    },
    tab: {
        width: (Dimensions.get('screen').width <= 500) ? (Dimensions.get('screen').width / 3) - 15 : (Dimensions.get('screen').width / 3) - 300,
        height: 40,
        backgroundColor: '#FFFFFF',
        borderTopLeftRadius: 6,
        borderBottomLeftRadius: 6,
        alignItems: 'center',
        justifyContent: 'center'
    },
    modal: {
        width: (Dimensions.get('screen').width <= 500) ? '90%' : '70%',
        height: 'auto',
        borderRadius: 10,
        backgroundColor: '#FFFFFF',
        paddingHorizontal: (Dimensions.get('screen').width <= 500) ? 20 : 150,
        paddingVertical: 25,
        justifyContent: 'center'
    }
});