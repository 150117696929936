import React from 'react';
import { StyleSheet, Text, View, StatusBar, ScrollView, Switch, Dimensions, Alert, Image, Platform, Modal, TouchableOpacity as TouchButton, TouchableOpacity, TextInput } from 'react-native';
import { ActivityIndicator } from 'react-native-paper';
import Constants from 'expo-constants';
import { AntDesign, Feather } from '@expo/vector-icons';
import RNPickerSelect from 'react-native-picker-select';
import PhoneInput from "react-native-phone-number-input";
import * as Crypto from 'expo-crypto';
import AsyncStorage from '@react-native-async-storage/async-storage';
import * as ImagePicker from 'expo-image-picker';
import * as ImageManipulator from 'expo-image-manipulator';
import Moment from 'moment';
import 'moment/locale/it';
import { ProgressBar } from 'react-native-paper';
import * as WebBrowser from 'expo-web-browser';
import * as Application from 'expo-application';
import * as Device from 'expo-device';
import LoadingScreen from './LoadingScreen';
import CryptoJS from 'crypto-js';
import axios from 'axios';

export default class ProfiloScreen extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            image: '',
            loading: false,
            loadingUser: false,
            nome: '',
            cognome: '',
            customerId: '',
            subscriptionId: '',
            pagamento: '',
            dataScadenza: null,
            isProva: null,
            numberUser: 0,
            userActive: 0,
            isLoading: true,
            percentualeUser: 0,
            devices: [],
            idUtente: 0,
            showModal: false,
            loadingTicket: false,
            titolo: '',
            messaggio: '',
            emailTest: '',
            isActiveStatistic: false
        }
    }

    componentDidMount() {
        this._unsubscribe = this.props.navigation.addListener('focus', () => {
            this.checkStatistic()
            this.getImage()
            this.getData()
            this.getBilling()
            this.getEmailTest()
        });
        this.checkAuth()
        this.checkStatistic()
        this.getImage()
        this.getData()
        this.getBilling()
        this.getEmailTest()
    }

    componentWillUnmount() {
        this._unsubscribe
    }

    checkAuth = async () => {
        const id = await AsyncStorage.getItem('id')
        if (id != null && id != undefined && id != '') {

        } else {
            this.props.navigation.reset({
                index: 0,
                routes: [{ name: 'Login' }],
            })
        }
    }

    checkStatistic = async () => {
        const stattistiche = await AsyncStorage.getItem('statistiche')
        if (stattistiche == 'true') {
            this.setState({ isActiveStatistic: true })
        } else {
            this.setState({ isActiveStatistic: false })
        }
    }

    reuqestAbilitaStatistic = () => {
        if (confirm("Attiva statistiche! Sei sicuro di voler attivare il Business Intelligence?") == true) {
            this.activateStatistic()
        }
    }

    activateStatistic = async () => {
        await AsyncStorage.setItem('statistiche', 'true')
        this.setState({ isActiveStatistic: true })
        alert(`Statistiche Attivate! Il Business Intelligence è stato attivato con successo`)
    }

    getEmailTest = async () => {
        const email = await AsyncStorage.getItem('email')
        this.setState({ emailTest: email.split('@')[1] })
    }

    getBilling = async () => {
        this.setState({ isLoading: true })
        const id = await AsyncStorage.getItem('id')
        this.setState({ idUtente: id })
        const token = CryptoJS.MD5('8Hsh-zjh83sdhy83Hbz').toString(CryptoJS.enc.Hex)
        let formData = new FormData();
        formData.append('token', token);
        formData.append('id', id);
        axios.post('https://clinicautomation.it/app/get_subscription_detail.php', formData, {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'multipart/form-data'
            }
        })
        .then(async (response) => {
            const json = response.data;
            if (json.code == true) {
                let percentuale = (json.users_active / json.number_users) * 100;
                percentuale = percentuale.toString().split('.')[0]
                let percentualeProgress;
                if (percentuale < 10) {
                    percentualeProgress = `0.0${percentuale}`;
                } else if (percentuale >= 100) {
                    percentualeProgress = `1`;
                } else {
                    percentualeProgress = `0.${percentuale}`;
                }
                this.setState({ 
                    customerId: json.customer_id,
                    /*subscriptionId: json.subscription_id,
                    pagamento: json.pagamento,
                    dataScadenza: json.data_scadenza,
                    isProva: json.is_prova,*/
                    numberUser: parseInt(json.number_users),
                    userActive: parseInt(json.users_active),
                    percentualeUser: parseFloat(percentualeProgress),
                    devices: JSON.parse(json.devices),
                    isLoading: false
                })
            } else {
                alert(`Attenzione! ${json.message}`)
            }
        }).catch((error) => {
            console.error(error);
        })
    }

    disactivateProfileConfirm = () => {
        Alert.alert(
            "Attenzione",
            "Sei sicuro di voler disattivare il profilo diquesto dispositivo?",
            [
                { text: "Disattiva", onPress: () => this.disactivateProfile() },
                { text: "Annulla", style: "cancel" }
            ]
        );
    }

    disactivateProfile = async () => {
        this.setState({ loadingUser: true })
        const id = await AsyncStorage.getItem('id')
        let devideId;
        if (Platform.OS == 'android') {
            devideId = Application.androidId;
        } else {
            devideId = await Application.getIosIdForVendorAsync()
        }
        let arrayDevices = this.state.devices;
        if (arrayDevices.includes(devideId)) {
            const token = CryptoJS.MD5('8Hsh-zjh83sdhy83Hbz').toString(CryptoJS.enc.Hex)
            arrayDevices = arrayDevices.filter(e => e !== devideId)
            let formData = new FormData();
            formData.append('token', token);
            formData.append('id_user', id);
            formData.append('devices', JSON.stringify(arrayDevices))
            fetch(`https://clinicautomation.it/app/not_active.php`, {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'multipart/form-data'
                },
                body: formData
            })
            .then((response) => response.json())
            .then(async (json) => {
                this.setState({ loadingUser: false })
                this.props.navigation.navigate('UserActive', { message: json.message, message2: json.message2 })
            }).catch((error) => {
                this.setState({ loadingUser: false })
                console.error(error);
            })
        } else {
            this.setState({ loadingUser: false })
            alert("Attenzione! Non è stato possibile disattivare il dispositivo. Riprova più tardi")
        }
    }

    getImage = async () => {
        let image = await AsyncStorage.getItem('image')
        if (image != null && image != undefined && image != '') {
            this.setState({ image })
        }
    }

    getData = async () => {
        let nome = await AsyncStorage.getItem('nome')
        let cognome = await AsyncStorage.getItem('cognome')
        this.setState({ nome, cognome })
    }

    requestLogout = () => {
        if (confirm("Attenzione! Sei sicuro di voler uscire?") == true) {
            this.logout()
        }
    }

    requestElimination = () => {
        Alert.alert(
            "Attenzione",
            "Sei sicuro di voler eliminare il tuo account?",
            [
              {
                text: "Annulla",
                style: "cancel"
              },
              { text: "Elimina", onPress: () => this.logout() }
            ]
        );
    }

    logout = async () => {
        await AsyncStorage.removeItem('id')
        await AsyncStorage.removeItem('nome')
        await AsyncStorage.removeItem('cognome')
        //await AsyncStorage.removeItem('data_scadenza')
        await AsyncStorage.removeItem('email')
        await AsyncStorage.removeItem('password')
        await AsyncStorage.removeItem('telefono')
        /*await AsyncStorage.removeItem('data_inizio')
        await AsyncStorage.removeItem('is_prova')
        await AsyncStorage.removeItem('periodo_prova')
        await AsyncStorage.removeItem('abbonamento_attivo')*/
        await AsyncStorage.removeItem('ragione_sociale')
        await AsyncStorage.removeItem('nome_studio')
        await AsyncStorage.removeItem('image')
        await AsyncStorage.removeItem('via')
        await AsyncStorage.removeItem('citta')
        await AsyncStorage.removeItem('piva')
        await AsyncStorage.removeItem('statistiche')
        await AsyncStorage.removeItem('codice_univoco')
        setTimeout(() => {
            this.props.navigation.reset({
                index: 0,
                routes: [{ name: 'Login' }],
            })
        }, 1000);
    }

    pickImage = async () => {
        let permission = await ImagePicker.getMediaLibraryPermissionsAsync();
        if (permission.status != 'granted') {
            let request = await ImagePicker.requestMediaLibraryPermissionsAsync();
            if (request.status != 'granted') {
                alert("Per caricare un'immagine devi acconsentire il permesso per accedere alla tua galleria");
            } else {
                this.imageGallery()
            }
        } else {
            this.imageGallery()
        }
    }

    imageGallery = async () => {
        let result = await ImagePicker.launchImageLibraryAsync({
            mediaTypes: ImagePicker.MediaTypeOptions.Images,
            allowsEditing: true,
            aspect: [4, 4],
            quality: 0.8
        })
        if (!result.canceled) {
            this.setState({ loading: true })
            const manipulateImge = await ImageManipulator.manipulateAsync(result.assets[0].uri, [{ resize: { width: 700 } }], { format: ImageManipulator.SaveFormat.JPEG })
            const token = CryptoJS.MD5('8Hsh-zjh83sdhy83Hbz').toString(CryptoJS.enc.Hex)
            let id = await AsyncStorage.getItem('id')
            // Convertire l'URI dell'immagine manipolata in un Blob
            const response = await fetch(manipulateImge.uri);
            const blob = await response.blob();

            // Creare un oggetto File dal Blob
            const file = new File([blob], `immagine_profilo_${Date.now()}.jpg`, {
                type: 'image/jpeg',
            });
            let formData = new FormData();
            formData.append('token', token);
            formData.append('id_user', id);
            formData.append('immagine', file);
            axios.post('https://clinicautomation.it/app/update_image_profilo.php', formData, {
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'multipart/form-data'
                }
            })
            .then(async (response) => {
                const json = response.data;
                if (json.code == true) {
                    await AsyncStorage.setItem('image', json.image)
                    this.setState({ image: json.image, loading: false })
                } else {
                    this.setState({ loading: false })
                    alert(`Attenzione! ${json.message}`)
                }
            }).catch((error) => {
                this.setState({ loading: false })
                console.error(error);
            })
        }
    }

    /*checkPayment = () => {
        if (this.state.isProva == 1) {
            this.props.navigation.navigate('SalePage')
        } else {
            const dataScadenza = Moment(this.state.dataScadenza).format('YYYY-MM-DD HH:mm:ss')
            const thisDate = Moment(new Date()).format('YYYY-MM-DD HH:mm:ss')
            if (thisDate >= dataScadenza) {
                this.props.navigation.navigate('SalePage')
            } else {
                Alert.alert(
                    "Attenzione",
                    "Potrai riattivare l'abbonamento allo scadere del piano precedente.",
                    [
                        { text: "OK" }
                    ]
                );
            }
        }
    }*/

    sendTicket = async () => {
        if (this.state.titolo != '' && this.state.messaggio != '') {
            this.setState({ loadingTicket: true })
            const nome = await AsyncStorage.getItem('nome')
            const cognome = await AsyncStorage.getItem('cognome')
            const email = await AsyncStorage.getItem('email')
            const token = await Crypto.digestStringAsync(Crypto.CryptoDigestAlgorithm.MD5, '8Hsh-zjh83sdhy83Hbz')
            let formData = new FormData();
            formData.append('token', token);
            formData.append('nome', nome);
            formData.append('cognome', cognome);
            formData.append('email', email);
            formData.append('titolo', this.state.titolo);
            formData.append('messaggio', this.state.messaggio.replace('\n', '<br>'));
            axios.post('https://clinicautomation.it/app/send_ticket.php', formData, {
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'multipart/form-data'
                }
            })
            .then(async (response) => {
                const json = response.data;
                if (json.code == true) {
                    alert(`Ticket aperto! ${json.message}`)
                    this.setState({ loadingTicket: false, showModal: false, titolo: '', messaggio: '' })
                } else {
                    this.setState({ loadingTicket: false })
                    alert("Attenzione! Qualcosa è andato storto. Riprova più tardi")
                }
            }).catch((error) => {
                this.setState({ loadingTicket: false })
                console.error(error);
            })
        } else {
            alert("Attenzione! Compila il titolo ed il messaggio per inviare il ticket")
        }
    }

    handleGoBack = () => {
        if (this.props.navigation.canGoBack()) {
            this.props.navigation.goBack();
        } else {
            this.props.navigation.navigate('App'); // Schermata di fallback
        }
    }

    render() {
        return(
            <View style={styles.container}>
                <StatusBar translucent={true} barStyle='light-content' backgroundColor='transparent' />
                <View style={styles.header}>
                    <TouchableOpacity style={styles.backButton} onPress={this.handleGoBack}>
                        <Feather name='arrow-left' color='#fff' size={23} />
                        <Text style={{ fontFamily: 'DM-Medium', fontSize: 14, color: '#fff', marginLeft: 6 }}>Profilo</Text>
                    </TouchableOpacity>
                </View>
                <ScrollView showsVerticalScrollIndicator={false}>
                    <View style={styles.headImage}>
                        <TouchableOpacity onPress={this.pickImage}>
                            <Image source={(this.state.image != '') ? { uri: this.state.image } : require('../assets/user-default-dentop.png')} resizeMode='cover' style={{ width: 100, height: 100, borderRadius: 15 }} />
                            <View style={styles.containerImage}>
                                {(this.state.loading)
                                    ?
                                    <ActivityIndicator size='small' color='#000' />
                                    :
                                    <AntDesign name='edit' color='#000' size={26} />
                                }
                            </View>
                        </TouchableOpacity>
                        <Text style={{ fontFamily: 'DM-Bold', fontSize: 19, textAlign: 'center', marginTop: 10 }}>{this.state.nome} {this.state.cognome}</Text>
                    </View>
                    {/*(this.state.isProva == 0)
                        &&
                        <View style={styles.containerLink}>
                            <View style={{ marginBottom: 20 }}>
                                <View style={[styles.buttonLink, { width: Dimensions.get('screen').width - 40 }]}>
                                    <Image source={require('../assets/account.png')} resizeMode='contain' style={{ width: 150, height: 130 }} />
                                    <View style={{ marginLeft: 14 }}>
                                        <View style={styles.badge}>
                                            <Text style={{ fontFamily: 'DM-Medium', fontSize: 12, color: '#fff' }}>L'abbonamento scade</Text>
                                        </View>
                                        <Text style={{ fontFamily: 'DM-Medium', fontSize: 14 }}>Il giorno {Moment(this.state.dataScadenza).format('DD/MM/YYYY')}{'\n'}alle {Moment(this.state.dataScadenza).format('HH:mm')}</Text>
                                        <Text style={{ fontFamily: 'DM-Regular', fontSize: 11, color: '#6d6e72' }}>Il tuo abbonamento{'\n'}è attivo</Text>
                                    </View>
                                </View>
                            </View>
                        </View>
                    */}
                   <View style={styles.containerLink}>
                        <View style={{ marginBottom: 20, alignItems: 'center' }}>
                            <View style={styles.buttonUserActive}>
                                <Text style={{ fontFamily: 'DM-Bold', fontSize: 18, marginBottom: 10 }}>Utenti Attivi</Text>
                                <Text style={{ fontFamily: 'DM-Medium', fontSize: 14, marginBottom: 10 }}>{this.state.userActive} di {this.state.numberUser}</Text>
                                <ProgressBar color='#0070FF' progress={this.state.percentualeUser} style={{ height: 12, width: '100%', borderRadius: 30 }} />
                                <View style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                                    <Text style={{ fontFamily: 'DM-Regular', fontSize: 13 }}>{this.state.userActive}</Text>
                                    <Text style={{ fontFamily: 'DM-Regular', fontSize: 13 }}>{this.state.numberUser}</Text>
                                </View>
                                {(this.state.isLoading == false && this.state.userActive >= this.state.numberUser)
                                    &&
                                    <TouchableOpacity style={[styles.button, { marginBottom: 0, marginTop: 10 }]} onPress={this.disactivateProfileConfirm}>
                                        {(this.state.loadingUser)
                                            ?
                                            <ActivityIndicator color='#fff' size='small' />
                                            :
                                            <Text style={{ fontFamily: 'DM-Bold', fontSize: 18, color: '#fff' }}>Disattiva profilo</Text>
                                        }
                                    </TouchableOpacity>
                                }
                            </View>
                        </View>
                    </View>
                    <View style={styles.listButton}>
                        <TouchableOpacity style={styles.button} onPress={() => this.props.navigation.navigate('EditProfilo')}>
                            <Text style={{ fontFamily: 'DM-Bold', fontSize: 16, color: '#fff' }}>Modifica profilo</Text>
                            <AntDesign name='right' color='#fff' size={22} style={{ position: 'absolute', top: 9, bottom: 0, right: 15 }} />
                        </TouchableOpacity>
                        <TouchableOpacity style={styles.button} onPress={() => this.props.navigation.navigate('EditInformazioni')}>
                            <Text style={{ fontFamily: 'DM-Bold', fontSize: 16, color: '#fff' }}>Informazioni aziendali</Text>
                            <AntDesign name='right' color='#fff' size={22} style={{ position: 'absolute', top: 9, bottom: 0, right: 15 }} />
                        </TouchableOpacity>
                        {/*(this.state.isActiveStatistic)
                            ?
                            <TouchableOpacity style={styles.button} onPress={() => this.props.navigation.navigate('Statistiche')}>
                                <Text style={{ fontFamily: 'DM-Bold', fontSize: 16, color: '#fff' }}>Business Intelligence</Text>
                                <AntDesign name='right' color='#fff' size={22} style={{ position: 'absolute', top: 9, bottom: 0, right: 15 }} />
                            </TouchableOpacity>
                            :
                            <TouchableOpacity style={styles.button} onPress={this.reuqestAbilitaStatistic}>
                                <Text style={{ fontFamily: 'DM-Bold', fontSize: 16, color: '#fff' }}>Attiva statistiche</Text>
                                <AntDesign name='right' color='#fff' size={22} style={{ position: 'absolute', top: 9, bottom: 0, right: 15 }} />
                            </TouchableOpacity>
                        */}
                        {/*(this.state.emailTest !== 'icloud.com' && this.state.idUtente != 16 && this.state.idUtente != '16')
                            &&
                            <TouchableOpacity style={styles.button} onPress={() => this.props.navigation.navigate('EditFatturazione')}>
                                <Text style={{ fontFamily: 'DM-Bold', fontSize: 16, color: '#fff' }}>Fatturazione</Text>
                                <AntDesign name='right' color='#fff' size={22} style={{ position: 'absolute', top: 9, bottom: 0, right: 15 }} />
                            </TouchableOpacity>
                        */}
                        {/*(this.state.idUtente != 16 && this.state.idUtente != '16' && this.state.emailTest !== 'icloud.com')
                            ?
                            (this.state.pagamento == 'Stripe')
                                ?
                                <TouchableOpacity style={styles.button} onPress={() => this.props.navigation.navigate('Billing', { customer_id: this.state.customerId })}>
                                    <Text style={{ fontFamily: 'DM-Bold', fontSize: 16, color: '#fff' }}>Abbonamento</Text>
                                    <AntDesign name='right' color='#fff' size={22} style={{ position: 'absolute', top: 9, bottom: 0, right: 15 }} />
                                </TouchableOpacity>
                                :
                                (this.state.pagamento == 'PayPal')
                                    ?
                                    <TouchableOpacity style={styles.button} onPress={() => this.props.navigation.navigate('PayPalBilling', { subscription: this.state.subscriptionId, numberUser: this.state.numberUser })}>
                                        <Text style={{ fontFamily: 'DM-Bold', fontSize: 16, color: '#fff' }}>Abbonamento</Text>
                                        <AntDesign name='right' color='#fff' size={22} style={{ position: 'absolute', top: 9, bottom: 0, right: 15 }} />
                                    </TouchableOpacity>
                                    :
                                    <TouchableOpacity style={styles.button} onPress={this.checkPayment}>
                                        <Text style={{ fontFamily: 'DM-Bold', fontSize: 16, color: '#fff' }}>Abbonamento</Text>
                                        <AntDesign name='right' color='#fff' size={22} style={{ position: 'absolute', top: 9, bottom: 0, right: 15 }} />
                                    </TouchableOpacity>
                            :
                            null
                        */}
                        <TouchableOpacity style={styles.button} onPress={() => this.setState({ showModal: true })}>
                            <Text style={{ fontFamily: 'DM-Bold', fontSize: 16, color: '#fff' }}>Apri un ticket</Text>
                            <AntDesign name='right' color='#fff' size={22} style={{ position: 'absolute', top: 9, bottom: 0, right: 15 }} />
                        </TouchableOpacity>
                        <TouchableOpacity style={styles.button} onPress={this.requestLogout}>
                            <Text style={{ fontFamily: 'DM-Bold', fontSize: 16, color: '#fff' }}>Esci</Text>
                            <AntDesign name='poweroff' color='#fff' size={18} style={{ position: 'absolute', top: 11, bottom: 0, right: 15 }} />
                        </TouchableOpacity>
                        <View style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                            <TouchableOpacity onPress={async () => await WebBrowser.openBrowserAsync('https://www.iubenda.com/termini-e-condizioni/84618713')} style={[styles.button, { marginBottom: 0, width: (Dimensions.get('screen').width <= 500) ? (Dimensions.get('screen').width / 2) - 22.5 : (Dimensions.get('screen').width / 2) - 310, paddingHorizontal: 5 }]}>
                                <AntDesign name='book' color='#fff' size={18} style={{ marginBottom: 4 }} />
                                <Text style={{ fontFamily: 'DM-Bold', fontSize: 13, color: '#fff' }}>Termini e Condizioni</Text>
                            </TouchableOpacity>
                            <TouchableOpacity onPress={async () => await WebBrowser.openBrowserAsync('https://www.iubenda.com/privacy-policy/84618713')} style={[styles.button, { marginBottom: 0, width: (Dimensions.get('screen').width <= 500) ? (Dimensions.get('screen').width / 2) - 22.5 : (Dimensions.get('screen').width / 2) - 310, paddingHorizontal: 5 }]}>
                                <AntDesign name='lock' color='#fff' size={18} style={{ marginBottom: 4 }} />
                                <Text style={{ fontFamily: 'DM-Bold', fontSize: 13, color: '#fff' }}>Privacy Policy</Text>
                            </TouchableOpacity>
                        </View>
                        <View style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start', paddingTop: 20 }}>
                            <TouchableOpacity onPress={async () => await WebBrowser.openBrowserAsync('https://clinicautomation.it/app/accordo-licenza.php')} style={[styles.button, { marginBottom: 0, width: (Dimensions.get('screen').width <= 500) ? (Dimensions.get('screen').width / 2) - 22.5 : (Dimensions.get('screen').width / 2) - 310, paddingHorizontal: 5 }]}>
                                <AntDesign name='book' color='#fff' size={18} style={{ marginBottom: 4 }} />
                                <Text style={{ fontFamily: 'DM-Bold', fontSize: 13, color: '#fff' }}>Condizioni di Fornitura</Text>
                            </TouchableOpacity>
                        </View>
                    </View>
                </ScrollView>
                <Modal
                    visible={this.state.showModal}
                    animationType='slide'
                    presentationStyle='pageSheet'
                    transparent={false}
                    onRequestClose={() => {
                        this.setState({ showModal: false, titolo: '', messaggio: '' })
                    }}
                >
                    <View style={styles.container}>
                        <View style={[styles.header, { paddingTop: 10 }]}>
                            <TouchButton style={styles.backButton} onPress={() => this.setState({ showModal: false, titolo: '', messaggio: '' })}>
                                <Feather name='arrow-left' color='#fff' size={23} />
                            </TouchButton>
                        </View>
                        <ScrollView showsVerticalScrollIndicator={false}>
                            <View style={[styles.containerCenter, { paddingBottom: 20 }]}>
                                <Text style={{ fontFamily: 'DM-Bold', fontSize: 18, textAlign: 'center', marginBottom: 25 }}>Apri un ticket</Text>
                                <TextInput
                                    style={styles.input}
                                    placeholderTextColor='#bfbfbf'
                                    placeholder='Titolo ticket'
                                    value={this.state.titolo}
                                    onChangeText={(titolo) => this.setState({ titolo })}
                                />
                                <TextInput
                                    style={[styles.input, { minHeight: 130 }]}
                                    placeholderTextColor='#bfbfbf'
                                    placeholder='Descrivi il motivo della tua richiesta'
                                    value={this.state.messaggio}
                                    multiline={true}
                                    numberOfLines={5}
                                    onChangeText={(messaggio) => this.setState({ messaggio })}
                                />
                                <View style={{ alignItems: 'center' }}>
                                    <TouchButton style={styles.buttonModal} onPress={this.sendTicket}>
                                        {(this.state.loadingTicket)
                                            ?
                                            <ActivityIndicator color='#fff' size='small' />
                                            :
                                            <Text style={{ fontFamily: 'DM-Bold', fontSize: 18, color: '#fff' }}>Apri ticket</Text>
                                        }
                                    </TouchButton>
                                </View>
                            </View>
                        </ScrollView>
                    </View>
                </Modal>
            </View>
        )
    }
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: '#FFF'
    },
    button: {
        backgroundColor: '#2484FF',
        borderRadius: 10,
        width: '100%',
        paddingHorizontal: 20,
        paddingVertical: 10,
        alignItems: 'center',
        marginBottom: 20,
        borderColor: '#fff',
        borderWidth: 1.5
    },
    containerCenter: {
        flex: 1,
        justifyContent: 'center',
        paddingHorizontal: (Dimensions.get('screen').width <= 500) ? 30 : 250,
        marginTop: 55,
        marginBottom: Dimensions.get('screen').height / 3
    },
    buttonModal: {
        backgroundColor: '#0070FF',
        borderRadius: 10,
        width: (Dimensions.get('screen').width <= 500) ? '100%' : '30%',
        paddingHorizontal: 20,
        paddingVertical: 15,
        alignItems: 'center'
    },
    input: {
        backgroundColor: '#f5f5f5',
        borderRadius: 10,
        width: '100%',
        paddingHorizontal: 20,
        paddingVertical: 10,
        height: 55,
        marginBottom: 20,
        fontFamily: 'DM-Regular',
        color: '#000',
        fontSize: 16
    },
    header: {
        backgroundColor: '#0070FF',
        paddingHorizontal: 30,
        paddingBottom: 10,
        paddingTop: 10 + Constants.statusBarHeight,
        justifyContent: 'flex-start',
        flexDirection: 'row'
    },
    containerLink: {
        paddingHorizontal: 20,
        paddingBottom: 10
    },
    backButton: {
        backgroundColor: '#2484FF',
        width: 'auto',
        height: 50,
        borderRadius: 10,
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        paddingHorizontal: 15
    },
    headImage: {
        paddingVertical: 35,
        paddingHorizontal: 20,
        alignItems: 'center'
    },
    listButton: {
        backgroundColor: '#0070FF',
        paddingVertical: 30,
        paddingHorizontal: (Dimensions.get('screen').width <= 500) ? 20 : 300,
        height: Dimensions.get('screen').height / 1.65 + 100
    },
    containerImage: {
        width: 100,
        height: 100,
        borderRadius: 15,
        backgroundColor: '#ffffff1c',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        margin: 'auto',
        zIndex: 2
    },
    buttonLink: {
        backgroundColor: '#fff',
        borderRadius: 10,
        flexDirection: 'row',
        alignItems: 'center',
        paddingHorizontal: 5,
        paddingVertical: 13,
        width: (Dimensions.get('screen').width / 2) - 30,
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 1,
        },
        shadowOpacity: 0.22,
        shadowRadius: 2.22,
        elevation: 3,
    },
    buttonUserActive: {
        backgroundColor: '#fff',
        borderRadius: 10,
        paddingHorizontal: 15,
        paddingVertical: 15,
        width:  (Dimensions.get('screen').width <= 500) ?  Dimensions.get('screen').width - 40 : Dimensions.get('screen').width - 300,
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 1,
        },
        shadowOpacity: 0.22,
        shadowRadius: 2.22,
        elevation: 3,
    },
    badge: {
        backgroundColor: '#b08d00',
        paddingHorizontal: 6,
        paddingVertical: 3,
        borderRadius: 10,
        marginBottom: 6
    }
});