import React from "react";
import {
  StyleSheet,
  Text,
  View,
  StatusBar,
  ScrollView,
  Dimensions,
  Alert,
  Modal,
  TouchableOpacity,
  TextInput,
} from "react-native";
import { ActivityIndicator, Switch } from "react-native-paper";
import Constants from "expo-constants";
import { Feather } from "@expo/vector-icons";
import RNPickerSelect from "react-native-picker-select";
import PhoneInput from "react-native-phone-number-input";
import * as Crypto from "expo-crypto";
import AsyncStorage from "@react-native-async-storage/async-storage";
import CryptoJS from "crypto-js";
import axios from "axios";

export default class PazienteNuovoScreen extends React.Component {
  constructor(props) {
    super(props);
    this.phoneRef = React.createRef();
    this.state = {
      loading: false,
      nome: "",
      cognome: "",
      email: "",
      paziente: null,
      fonte: null,
      sesso: null,
      impiego: null,
      isFigli: false,
      isFigliMinori: false,
      telefono: "",
      showModal: false,
      contattoDoppione: false,
      country: {
        callingCode: ["39"],
        cca2: "IT",
        currency: ["EUR"],
        flag: "flag-it",
        name: "Italy",
        region: "Europe",
        subregion: "Southern Europe",
      },
      creaVisita: false,
      isExistNumber: false,
    };
  }

  componentDidMount() {
    this.checkAuth();
  }

  checkAuth = async () => {
    const id = await AsyncStorage.getItem("id");
    if (id != null && id != undefined && id != "") {
    } else {
      this.props.navigation.reset({
        index: 0,
        routes: [{ name: "Login" }],
      });
    }
  };

  salvaPaziente = async () => {
    this.setState({ loading: true });
    if (
      this.state.nome != "" &&
      this.state.cognome != "" &&
      this.state.telefono != ""
    ) {
      const isValidaNumber = this.phoneRef.current.isValidNumber(
        this.state.telefono
      );

      // setTimeout(()=> {
      //     isExistNumber = this.checkIfNumberExiting(this.state.telefono);
      // }, 500)

      if (isValidaNumber == true && this.state.isExistNumber === 0) {
        const token = CryptoJS.MD5("8Hsh-zjh83sdhy83Hbz").toString(
          CryptoJS.enc.Hex
        );
        let id = await AsyncStorage.getItem("id");
        let formData = new FormData();
        formData.append("token", token);
        formData.append("id_user", id);
        formData.append("email", this.state.email);
        formData.append("nome", this.state.nome);
        formData.append("cognome", this.state.cognome);
        formData.append("telefono", this.state.telefono);
        formData.append("country_code", JSON.stringify(this.state.country));
        formData.append("paziente", this.state.paziente);
        formData.append("fonte", this.state.fonte);
        formData.append("sesso", this.state.sesso);
        formData.append("impiego", this.state.impiego);
        formData.append("figli", this.state.isFigli ? 1 : 0);
        formData.append("figli_minori", this.state.isFigliMinori ? 1 : 0);
        axios
          .post(
            "https://clinicautomation.it/app/nuovo_paziente_2.php",
            formData,
            {
              headers: {
                Accept: "application/json",
                "Content-Type": "multipart/form-data",
              },
            }
          )
          .then(async (response) => {
            const json = response.data;
            if (json.code == true) {
              this.setState({ loading: false });
              if (this.state.creaVisita == true) {
                const itemUser = {
                  email: this.state.email,
                  nome: this.state.nome,
                  cognome: this.state.cognome,
                  id: json.utente,
                  telefono: this.state.telefono,
                  country_code: this.state.country,
                  paziente: this.state.paziente,
                  fonte: this.state.fonte,
                  sesso: this.state.sesso,
                  impiego: this.state.impiego,
                  figli: this.state.isFigli ? 1 : 0,
                  figli_minori: this.state.isFigliMinori ? 1 : 0,
                };
                this.props.navigation.navigate("VisitaNuovo", {
                  user: itemUser,
                });
              } else {
                this.handleGoBack();
              }
            } else {
              this.setState({ loading: false });
              Alert.alert("Attenzione", json.message, [{ text: "OK" }]);
            }
          })
          .catch((error) => {
            this.setState({ loading: false });
            console.error(error);
          });
      } else {
        this.setState({ loading: false });
        alert("Attenzione! Il numero di telefono inserito non è valido");
      }
    } else {
      this.setState({ loading: false });
      alert("Attenzione! Compila tutti i campi obbligatori");
    }
  };

  handleGoBack = () => {
    if (this.props.navigation.canGoBack()) {
      this.props.navigation.goBack();
    } else {
      this.props.navigation.navigate("App", { screen: "Paziente" });
    }
  };

  handleChangeTelefono = async (text) => {
    this.setState({ telefono: text });
    if (text.length > 7) {
      await this.checkIfNumberExiting(text);
    }
  };

  checkIfNumberExiting = async (number) => {
    if (number) {
      const token = CryptoJS.MD5("8Hsh-zjh83sdhy83Hbz").toString(
        CryptoJS.enc.Hex
      );
      let formData = new FormData();
      formData.append("token", token);
      formData.append("telefono", number);
      axios
        .post("https://clinicautomation.it/app/check_contatto_esistente.php", formData, {
          headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          const json = response.data;
          //this.setState({ isExistNumber: json });
          if (json == 1) {
            this.setState({ showModal: true });
            if (confirm("Attenzione! Il numero di telefono inserito è già presente nella rubrica, vuoi registrarlo lo stesso?") == true) {
                this.handleAccettaContattoDoppio()
            }           
          }else {
            this.setState({ isExistNumber: 0 });
          }
        })
        .catch((error) => {
          this.setState({ loading: false });
          console.error(error);
        });
    }
  };

  handleAccettaContattoDoppio = () => {
    this.setState({ isExistNumber: 0 });
  };

  render() {
    return (
      <View style={styles.container}>
        <StatusBar
          translucent={true}
          barStyle="light-content"
          backgroundColor="transparent"
        />
        <View style={styles.header}>
          <TouchableOpacity
            style={styles.backButton}
            onPress={this.handleGoBack}
          >
            <Feather name="arrow-left" color="#fff" size={23} />
            <Text
              style={{
                fontFamily: "DM-Medium",
                fontSize: 14,
                color: "#fff",
                marginLeft: 6,
              }}
            >
              Aggiungi Paziente
            </Text>
          </TouchableOpacity>
        </View>
        <ScrollView showsVerticalScrollIndicator={false}>
          <View style={styles.containerCenter}>
            <Text
              style={{
                fontFamily: "DM-Medium",
                fontSize: 16,
                marginBottom: 10,
              }}
            >
              Nome*
            </Text>
            <TextInput
              style={styles.input}
              placeholderTextColor="#bfbfbf"
              placeholder="Nome"
              value={this.state.nome}
              onChangeText={(nome) => this.setState({ nome })}
              autoCapitalize="words"
            />
            <Text
              style={{
                fontFamily: "DM-Medium",
                fontSize: 16,
                marginBottom: 10,
              }}
            >
              Cognome*
            </Text>
            <TextInput
              style={styles.input}
              placeholderTextColor="#bfbfbf"
              placeholder="Cognome"
              value={this.state.cognome}
              onChangeText={(cognome) => this.setState({ cognome })}
              autoCapitalize="words"
            />
            <Text
              style={{
                fontFamily: "DM-Medium",
                fontSize: 16,
                marginBottom: 10,
              }}
            >
              Email
            </Text>
            <TextInput
              style={styles.input}
              placeholderTextColor="#bfbfbf"
              placeholder="Email"
              value={this.state.email}
              onChangeText={(email) =>
                this.setState({ email: email.toLowerCase().trim() })
              }
              keyboardType="email-address"
              autoCapitalize="none"
            />
            <Text
              style={{
                fontFamily: "DM-Medium",
                fontSize: 16,
                marginBottom: 10,
              }}
            >
              Telefono*
            </Text>
            <PhoneInput
              ref={this.phoneRef}
              defaultCode="IT"
              layout="first"
              withDarkTheme={false}
              withShadow={false}
              placeholder="Numero di cellulare"
              containerStyle={{
                width: "100%",
                borderTopRightRadius: 10,
                borderBottomRightRadius: 10,
                marginBottom: 20,
              }}
              textContainerStyle={{
                borderTopRightRadius: 10,
                borderBottomRightRadius: 10,
                paddingVertical: 10,
                height: 55,
                backgroundColor: "#f5f5f5",
              }}
              textInputStyle={{ fontFamily: "DM-Regular", fontSize: 16 }}
              codeTextStyle={{ fontFamily: "DM-Regular", fontSize: 16 }}
              flagButtonStyle={{
                backgroundColor: "#e8e8e8",
                borderTopLeftRadius: 10,
                borderBottomLeftRadius: 10,
              }}
              filterProps={{ placeholder: "Cerca nazione o prefisso" }}
              value={this.state.telefono}
              onChangeText={
                (telefono) =>
                  this.handleChangeTelefono(
                    telefono
                  ) /*this.setState({ telefono })*/
              }
              onChangeCountry={(country) => this.setState({ country })}
              //onEndEditing={()=> this.checkIfNumberExiting(this.state.telefono)}
            />
            <View style={{ marginBottom: 20 }}>
              <Text
                style={{
                  fontFamily: "DM-Medium",
                  fontSize: 16,
                  marginBottom: 10,
                }}
              >
                Paziente
              </Text>
              <RNPickerSelect
                placeholder={{
                  label: "Seleziona il tipo di Paziente",
                  value: null,
                }}
                doneText="Fine"
                style={{
                  viewContainer: [
                    styles.input,
                    { marginBottom: 0, justifyContent: "center" },
                  ],
                  inputWeb: {
                    fontFamily: "DM-Regular",
                    fontSize: 16,
                    color: "#000",
                    borderWidth: 0,
                    backgroundColor: "#f5f5f5",
                  },
                }}
                onValueChange={(paziente) => this.setState({ paziente })}
                items={[
                  { label: "Nuovo", value: "Nuovo" },
                  { label: "Acquisito", value: "Acquisito" },
                ]}
              />
            </View>
            <View style={{ marginBottom: 20 }}>
              <Text
                style={{
                  fontFamily: "DM-Medium",
                  fontSize: 16,
                  marginBottom: 10,
                }}
              >
                Fonte
              </Text>
              <RNPickerSelect
                placeholder={{
                  label: "Seleziona il tipo di Fonte",
                  value: this.state.fonte,
                }}
                doneText="Fine"
                style={{
                  viewContainer: [
                    styles.input,
                    { marginBottom: 0, justifyContent: "center" },
                  ],
                  inputWeb: {
                    fontFamily: "DM-Regular",
                    fontSize: 16,
                    color: "#000",
                    borderWidth: 0,
                    backgroundColor: "#f5f5f5",
                  },
                }}
                onValueChange={(fonte) => this.setState({ fonte })}
                items={[
                  { label: "Passaparola", value: "Passaparola" },
                  { label: "Google", value: "Google" },
                  { label: "Facebook", value: "Facebook" },
                  { label: "Stampa", value: "Stampa" },
                  { label: "Affisioni", value: "Affisioni" },
                  { label: "Tv", value: "Tv" },
                  { label: "Assicurazioni", value: "Assicurazioni" },
                ]}
              />
            </View>
            <View style={{ marginBottom: 20 }}>
              <Text
                style={{
                  fontFamily: "DM-Medium",
                  fontSize: 16,
                  marginBottom: 10,
                }}
              >
                Sesso
              </Text>
              <RNPickerSelect
                placeholder={{
                  label: "Seleziona il tipo di Sesso",
                  value: this.state.sesso,
                }}
                doneText="Fine"
                style={{
                  viewContainer: [
                    styles.input,
                    { marginBottom: 0, justifyContent: "center" },
                  ],
                  inputWeb: {
                    fontFamily: "DM-Regular",
                    fontSize: 16,
                    color: "#000",
                    borderWidth: 0,
                    backgroundColor: "#f5f5f5",
                  },
                }}
                onValueChange={(sesso) => this.setState({ sesso })}
                items={[
                  { label: "Uomo", value: "Uomo" },
                  { label: "Donna", value: "Donna" },
                ]}
              />
            </View>
            <View
              style={{
                marginBottom: 20,
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Text
                style={{
                  fontFamily: "DM-Medium",
                  fontSize: 16,
                  marginBottom: 10,
                }}
              >
                Figli
              </Text>
              <Switch
                color={"#0070FF"}
                value={this.state.isFigli}
                onValueChange={() =>
                  this.setState({ isFigli: !this.state.isFigli })
                }
              />
            </View>
            <View
              style={{
                marginBottom: 20,
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Text
                style={{
                  fontFamily: "DM-Medium",
                  fontSize: 16,
                  marginBottom: 10,
                }}
              >
                Figli minori
              </Text>
              <Switch
                color={"#0070FF"}
                value={this.state.isFigliMinori}
                onValueChange={() =>
                  this.setState({ isFigliMinori: !this.state.isFigliMinori })
                }
              />
            </View>
            <View style={{ marginBottom: 20 }}>
              <Text
                style={{
                  fontFamily: "DM-Medium",
                  fontSize: 16,
                  marginBottom: 10,
                }}
              >
                Impiego
              </Text>
              <RNPickerSelect
                placeholder={{
                  label: "Seleziona il tipo di Impiego",
                  value: this.state.impiego,
                }}
                doneText="Fine"
                style={{
                  viewContainer: [
                    styles.input,
                    { marginBottom: 0, justifyContent: "center" },
                  ],
                  inputWeb: {
                    fontFamily: "DM-Regular",
                    fontSize: 16,
                    color: "#000",
                    borderWidth: 0,
                    backgroundColor: "#f5f5f5",
                  },
                }}
                onValueChange={(impiego) => this.setState({ impiego })}
                items={[
                  { label: "Impiegato", value: "Impiegato" },
                  {
                    label: "Libero professionista",
                    value: "Libero professionista",
                  },
                  { label: "Imprenditore", value: "Imprenditore" },
                  { label: "Operaio", value: "Operaio" },
                  { label: "Studente", value: "Studente" },
                  { label: "Pensionato", value: "Pensionato" },
                  { label: "Altro", value: "Altro" },
                ]}
              />
            </View>
            <View style={{ alignItems: "center" }}>
              <TouchableOpacity
                style={[
                  styles.button,
                  {
                    width:
                      Dimensions.get("screen").width <= 500 ? "100%" : "30%",
                  },
                ]}
                onPress={this.salvaPaziente}
              >
                {this.state.loading ? (
                  <ActivityIndicator color="#fff" size="small" />
                ) : (
                  <Text
                    style={{
                      fontFamily: "DM-Bold",
                      fontSize: 18,
                      color: "#fff",
                    }}
                  >
                    Salva e continua
                  </Text>
                )}
              </TouchableOpacity>
            </View>
            {/* <Modal
              visible={this.state.showModal}
             animationType='slide'
                    presentationStyle='pageSheet'
            >
              <View style={{ paddingHorizontal: 15, paddingVertical: 20 }}>
                <Text
                  style={{
                    fontFamily: "DM-Bold",
                    fontSize: 17,
                    textAlign: "center",
                  }}
                >
                  Il numero telefonico è già presente in agenda, desideri procedere ugualmente con la registrazione?
                </Text>
              </View>              
              <View
                style={{
                  paddingHorizontal: 15,
                  paddingVertical: 20,
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "flex-end",
                }}
              >
                <TouchableOpacity
                  style={styles.buttonTermini}
                  onPress={this.setState({showModal: false})}
                >
                  <Text
                    style={{
                      fontFamily: "DM-Bold",
                      fontSize: 18,
                      color: "#fff",
                    }}
                  >
                    Cancella
                  </Text>
                </TouchableOpacity>
                <TouchableOpacity
                  style={styles.buttonTermini}
                  onPress={this.handleAccettaCondizioni}
                >
                  <Text
                    style={{
                      fontFamily: "DM-Bold",
                      fontSize: 18,
                      color: "#fff",
                    }}
                  >
                    Si
                  </Text>
                </TouchableOpacity>

              </View>
            </Modal>  */}
          </View>
        </ScrollView>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#fff",
  },
  containerCenter: {
    flex: 1,
    justifyContent: "center",
    paddingHorizontal: Dimensions.get("screen").width <= 500 ? 30 : 300,
    marginTop: 45,
    marginBottom: Dimensions.get("screen").height / 3,
  },
  button: {
    backgroundColor: "#0070FF",
    borderRadius: 10,
    width: "100%",
    paddingHorizontal: 20,
    paddingVertical: 15,
    alignItems: "center",
    marginTop: 20,
  },
  buttonTermini: {
    backgroundColor: "#0070FF",
    borderRadius: 10,
    paddingHorizontal: 20,
    paddingVertical: 15,
    alignItems: "center",
  },
  input: {
    backgroundColor: "#f5f5f5",
    borderRadius: 10,
    width: "100%",
    paddingHorizontal: 20,
    paddingVertical: 10,
    height: 55,
    marginBottom: 20,
    fontFamily: "DM-Regular",
    color: "#000",
    fontSize: 16,
  },
  header: {
    backgroundColor: "#0070FF",
    paddingHorizontal: 30,
    paddingBottom: 10,
    paddingTop: 10 + Constants.statusBarHeight,
    justifyContent: "flex-start",
    flexDirection: "row",
  },
  backButton: {
    backgroundColor: "#2484FF",
    width: "auto",
    height: 50,
    borderRadius: 10,
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    paddingHorizontal: 15,
  },
});
